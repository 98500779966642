import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';

const useOpenClose = (initialValue = false) => {
  const [openArgs, setOpenArgs] = useState(initialValue ? { isOpen: true } : {});
  const close = useCallback(() => {
    setOpenArgs({});
  }, []);
  const open = useCallback((args = { isOpen: true }) => {
    setOpenArgs(args);
  }, []);
  const empty = isEmpty(openArgs);
  const isOpen = !empty;
  return [isOpen, open, close, openArgs];
};

export default useOpenClose;
