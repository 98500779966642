/* eslint-disable react/require-default-props */
import slugify from 'client/util/slugify';
import FormsyText from 'components/FormsyText/FormsyText';
import SelectField from 'components/SelectField/SelectField';
import Formsy from 'formsy-react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { localizationData, surveyLanguages } from '../../../../../Intl/setup';
import Config from '../../../../../server/config';
import tr from '../../../../util/tr';
import withLocation from '../../../../util/withLocation';

import { Accordion, AccordionDrawer } from '../../../App/components/Accordion';
import ChatBubble from '../../../App/components/ChatBubble/ChatBubble';
import MobileMockup from '../../../App/components/MobileMockup/MobileMockup';

import { getProfile, getToken } from '../../../Auth/AuthReducer';

import {
  customizationInputChange,
  fileUploaded,
  inputChange,
  postSurvey,
  setSurveySubmitEnabled,
  setTransliterate,
  toggleTopPicker,
} from '../../SurveyActions';

import {
  canSubmit,
  getCustomization,
  getCustomizations,
  getId,
  getInputValue,
  getLogoUrl,
  getRand,
  getSubmitButtonMessage,
  getSurveys, getSurveyType,
  getTransliterate,
  surveyMessages,
} from '../../SurveyReducer';

import styles from './CustomizeEmail.css';

const languageItems = locale => Object.keys(surveyLanguages[locale]).map(lang =>
  <MenuItem key={lang} value={lang}>{surveyLanguages[locale][lang]}</MenuItem>);

const CustomizeEmail = (props) => {
  const formatMessage = props.intl.formatMessage;

  const handleTransliterate = (event, isInputChecked) => {
    props.setTransliterate(isInputChecked);
  };

  const handleChange = (event) => {
    props.inputChange(event.target.id, event.target.value);
  };

  const handleCustomizationChange = (event) => {
    props.customizationInputChange(event.target.id, event.target.value, props.surveyLanguage);
  };

  const handleChangeLanguage = (event) => {
    props.inputChange('surveyLanguage', event.target.value);
  };

  const enableButton = () => {
    props.setSurveySubmitEnabled(true);
  };

  const disableButton = () => {
    props.setSurveySubmitEnabled(false);
  };

  const handleSubmit = () => {
    props.setSurveySubmitEnabled(false, formatMessage(surveyMessages['Survey.Submitting']));
    const { _id: id, topColor, buttonColor, brand, name, fromName, fromEmail, replyTo, subject, intro, customizations, logoUrl, transliterate } = props;
    const properties = {
      id,
      topColor,
      buttonColor,
      brand,
      name,
      fromName,
      fromEmail,
      replyTo,
      subject,
      intro,
      customizations,
      language: props.surveyLanguage || props.intl.locale,
      transliterateSMS: transliterate,
    };
    if (logoUrl) {
      properties.logoUrl = logoUrl;
    }
    props.postSurvey(properties, props.token);
  };

  const repeatedName = props.surveys && props.surveys.filter(s => s.brand === props.brand && s.id !== props._id).length > 0;

  return (
    <Grid className={styles['main-grid']} style={props.style} fluid>
      <Row className={styles['main-content']}>
        <Col className={styles.controls} xs={12} sm={5} md={4} lg={4}>
          <Helmet title={formatMessage(surveyMessages['Survey.Personalize.Title'])} />
          <Paper className={styles.form}>
            <Formsy.Form
              onValid={enableButton}
              onInvalid={disableButton}
              onValidSubmit={handleSubmit}
            >
              <Accordion startPosition={0}>
                <AccordionDrawer key="acc-basic" title={formatMessage(surveyMessages['Survey.BasicCustomization'])}>
                  <FormsyText
                    id="brand"
                    name="brand"
                    value={props.brand}
                    label={formatMessage(surveyMessages['Survey.Brand'])}
                    onChange={handleChange}
                    required
                    autoFocus
                  />
                  {repeatedName ?
                    <FormsyText
                      id="name"
                      name="name"
                      value={props.name}
                      hintText={formatMessage(surveyMessages['Survey.Name.Hint'])}
                      label={formatMessage(surveyMessages['Survey.Name'])}
                      onChange={handleChange}
                      required
                      multiLine
                      rows={1}
                    /> : null}
                </AccordionDrawer>
                <AccordionDrawer
                  key="acc-advanced"
                  title={formatMessage(surveyMessages['Survey.AdvancedCustomization'])}
                >
                  <FormsyText
                    id="smsIntro"
                    name="smsIntro"
                    value={props.smsIntro}
                    multiLine
                    rows={3}
                    label={formatMessage(surveyMessages['Survey.Intro'])}
                    onChange={handleCustomizationChange}
                  />
                  <FormsyText
                    id="smsQuestion"
                    name="smsQuestion"
                    value={props.smsQuestion}
                    multiLine
                    rows={3}
                    label={formatMessage(surveyMessages['Survey.Question'])}
                    onChange={handleCustomizationChange}
                  />
                  <SelectField
                    id="surveyLanguage"
                    label={formatMessage(surveyMessages['Survey.BaseLanguage'])}
                    maxHeight={200}
                    value={props.surveyLanguage || props.intl.locale}
                    onChange={handleChangeLanguage}
                  >
                    {languageItems(props.intl.locale || Config.defaultLanguage)}
                  </SelectField>
                </AccordionDrawer>
              </Accordion>
              <RaisedButton
                type="submit"
                className={styles.submit}
                label={
                  props.submitButtonMessage ||
                  props._id ? formatMessage(surveyMessages['Survey.Save']) : formatMessage(surveyMessages['Survey.Next'])
                }
                disabled={!props.canSubmit}
                primary
              />
            </Formsy.Form>
          </Paper>
        </Col>
        <Col xs={12} sm={7} md={8} lg={8} className={styles.flexEnd}>
          <div className={styles.smsPreview}>
            <MobileMockup>
              <div className={styles.smsApp}>
                <ChatBubble>
                  <p>{props.smsIntroPreview}</p>
                  <p>{props.smsQuestionWithDefault}</p>
                  <p className={styles.smsLink}>{props.link}</p>
                </ChatBubble>
              </div>
            </MobileMockup>
          </div>
          <div className={`${styles.charCounter} ${props.numSms > 1 ? styles.error : ''}`}>
            {props.numSms > 1 ? `${props.numSms} SMS / ` : null}
            <FormattedMessage
              id="Survey.CharactersLeft"
              defaultMessage="{num} characters left."
              values={{ num: props.charCount }}
            />
          </div>
          {props.offerTransliteration ?
            <div className={styles.transliteration}>
              <FormattedMessage
                id="Survey.UsingNonGSMCharacters"
                defaultMessage="Using non GSM-friendly characters reduces sms length to 70 characters."
              />
              <Checkbox
                label={formatMessage(surveyMessages.offerTransliteration)}
                checked={props.transliterate}
                onCheck={handleTransliterate}
                labelStyle={{ fontWeight: 'normal' }}
              />
            </div> : null
          }
        </Col>
      </Row>
    </Grid>
  );
};

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
  let fromEmail = slugify(getInputValue('brand', state));
  if (fromEmail === null || fromEmail.length < 1) {
    fromEmail = 'surveys';
  }
  const brandWithDefault = getInputValue('brand', state) || ownProps.intl.formatMessage(surveyMessages['Survey.Brand']);

  const getDefaultQuestion = () => {
    const locale = getInputValue('surveyLanguage', state) || state.app.i18n.locale;
    const ld = localizationData[locale];
    if (ld && ld.messages && ld.messages['nps.question']) {
      return ld.messages['nps.question'];
    }
    // Should never reach here: return the question in the current language instead of the survey language
    return ownProps.intl.formatMessage(surveyMessages['nps.question']);
  };
  const link = 'https://t.ptnj.io/qQIMgTjT';
  const smsIntro = getCustomization('smsIntro', state);
  const smsQuestion = getCustomization('smsQuestion', state);
  let smsQuestionWithDefault = smsQuestion || getDefaultQuestion().replace('{brand}', brandWithDefault).replace('{marca}', brandWithDefault);
  const transliterate = getTransliterate(state);
  const offerTransliteration = ((smsIntro && tr(smsIntro).trim() !== smsIntro.trim()) || tr(smsQuestionWithDefault).trim() !== smsQuestionWithDefault.trim());
  let maxChars = !transliterate && offerTransliteration ? 70 : 160;
  const smsIntroPreview = transliterate && smsIntro ? tr(smsIntro) : smsIntro;
  if (transliterate) {
    smsQuestionWithDefault = tr(smsQuestionWithDefault);
  }
  const msgLength = (smsQuestionWithDefault.length + 1)
    + (smsIntro ? smsIntro.length + 1 : 0)
    + link.length;
  if (maxChars < msgLength) {
    // Multi message headers
    maxChars -= offerTransliteration ? 3 : 7;
  }
  const numSms = Math.ceil(msgLength / maxChars);
  const charCount = (numSms * maxChars) - msgLength;
  const _id = getId(state);
  const surveyType = getSurveyType(state) || ownProps.location.query.type || 'nps';
  const buttonColor = getInputValue('buttonColor', state) || (!_id && surveyType === 'star_five' ? '#f6d400' : '#333');
  return {
    canSubmit: canSubmit(state),
    _id,
    surveyType,
    topColor: getInputValue('topColor', state),
    buttonColor,
    brand: getInputValue('brand', state),
    name: getInputValue('name', state),
    fromName: getInputValue('fromName', state),
    fromEmail: `${fromEmail}@promoterninja.com`,
    replyTo: getInputValue('replyTo', state) || getProfile(state).email,
    question: getCustomization('question', state),
    subject: getCustomization('subject', state) || getInputValue('subject', state),
    intro: getInputValue('intro', state),
    smsQuestion,
    smsQuestionWithDefault,
    smsIntro,
    smsIntroPreview,
    customIntro: getCustomization('intro', state) || 'intro',
    surveyLanguage: getInputValue('surveyLanguage', state),
    token: getToken(state),
    submitButtonMessage: getSubmitButtonMessage(state),
    surveys: getSurveys(state),
    logoUrl: getLogoUrl(state),
    rand: `${getRand(state)}`,
    customizations: getCustomizations(state),
    link,
    numSms,
    charCount,
    transliterate,
    offerTransliteration,
  };
}

CustomizeEmail.propTypes = {
  intl: PropTypes.object,
  _id: PropTypes.string,
  topColor: PropTypes.string,
  buttonColor: PropTypes.string,
  surveyLanguage: PropTypes.string,
  brand: PropTypes.string,
  name: PropTypes.string,
  fromName: PropTypes.string,
  fromEmail: PropTypes.string,
  replyTo: PropTypes.string,
  smsQuestion: PropTypes.string,
  smsQuestionWithDefault: PropTypes.string.isRequired,
  surveys: PropTypes.array,
  smsIntro: PropTypes.string,
  smsIntroPreview: PropTypes.string,
  logoUrl: PropTypes.string,
  canSubmit: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  submitButtonMessage: PropTypes.string,
  postSurvey: PropTypes.func.isRequired,
  setSurveySubmitEnabled: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  customizations: PropTypes.array,
  customizationInputChange: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  numSms: PropTypes.number.isRequired,
  charCount: PropTypes.number.isRequired,
  transliterate: PropTypes.bool.isRequired,
  offerTransliteration: PropTypes.bool.isRequired,
  setTransliterate: PropTypes.func.isRequired,
};


const mapDispatchToProps = dispatch => ({
  setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage) {
    dispatch(setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage));
  },
  postSurvey(model, token) {
    dispatch(postSurvey(model, token));
  },
  togglePicker(show) {
    dispatch(toggleTopPicker(show));
  },
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
  fileUploaded(url) {
    dispatch(fileUploaded(url));
  },
  customizationInputChange(name, value, language) {
    dispatch(customizationInputChange(name, value, language, false));
  },
  setTransliterate(val) {
    dispatch(setTransliterate(val));
  },
});

export default withLocation(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomizeEmail)));
