import { Translate } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { getToken } from 'client/modules/Auth/AuthReducer';
import { getId, getSurveyLanguage, getSurveyLanguages } from 'client/modules/Surveys/SurveyReducer';
import callApi from 'client/util/apiCaller';
import { surveyLanguages } from 'Intl/setup';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

const EditDriverModal = ({ isOpen, onClose, onSubmit, onRemove, editing }) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const surveyId = useSelector(getId);
  const baseLanguage = useSelector(getSurveyLanguage);
  const inputRef = useRef();
  const { locale } = useIntl();
  const [names, setNames] = useState(editing?.translations || {});
  const setName = useCallback(
    (lang, name) => {
      setNames({ ...names, [lang]: name });
    },
    [names, setNames],
  );
  const translateDriver = useCallback(() => {
    if (!names[baseLanguage]) {
      return;
    }
    callApi(dispatch, `surveys/${surveyId}/drivers/translate`, token, 'POST', {
      driver: names[baseLanguage],
    }).then(({ translations }) => {
      setNames({ ...translations, ...names });
    });
  }, [dispatch, surveyId, token, names, baseLanguage]);
  const languages = useSelector(getSurveyLanguages);
  const translations = useMemo(
    () => languages.filter(lang => lang !== baseLanguage),
    [languages, baseLanguage],
  );
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit(names, editing?.driver);
      onClose();
    },
    [names, onSubmit],
  );
  const handleChange = useCallback(lang => e => setName(lang, e.target.value), [setName]);
  const handleRemove = useCallback(() => onRemove(editing.driver), [editing, onRemove]);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => inputRef.current?.focus(), 10);
    }
  }, [isOpen]);
  useEffect(() => {
    setNames(editing?.translations || {});
  }, [editing]);
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="edit-driver-title">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      >
        <DialogTitle id="edit-driver-title" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="h6">
            <FormattedMessage id="EditDriverModal.Title" defaultMessage="Edit driver" />
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: 'background.body',
            borderWidth: 0,
            borderColor: 'muted.lighter',
            borderStyle: 'solid',
            borderTopWidth: 1,
            borderBlockWidth: 1,
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6" sx={{ mt: 2 }}>
            <FormattedMessage id="EditDriverModal.Name" defaultMessage="Base language" />
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <TextField
              size="small"
              inputRef={inputRef}
              fullWidth
              value={names[baseLanguage] || ''}
              onChange={handleChange(baseLanguage)}
              label={surveyLanguages[locale][baseLanguage]}
              variant="outlined"
              sx={{ mb: 2 }}
              required
            />
            {translations.length > 0 && (
              <IconButton
                title="translate"
                size="small"
                sx={{ display: 'flex', margin: 'auto' }}
                onClick={translateDriver}
              >
                <Translate />
              </IconButton>
            )}
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            <FormattedMessage id="EditDriverModal.Translations" defaultMessage="Translations" />
          </Typography>
          {translations.map(lang => (
            <TextField
              size="small"
              key={lang}
              fullWidth
              value={names[lang] || ''}
              onChange={handleChange(lang)}
              label={surveyLanguages[locale][lang]}
              variant="outlined"
              sx={{ mb: 2 }}
              required
            />
          ))}
        </DialogContent>
        <DialogActions>
          {!editing?.driver || (
            <Button onClick={handleRemove} color="error" sx={{ marginRight: 'auto' }}>
              <FormattedMessage id="EditDriverModal.Delete" defaultMessage="Delete" />
            </Button>
          )}
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="EditDriverModal.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" variant="contained" color="primary">
            <FormattedMessage id="EditDriverModal.Save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

EditDriverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  editing: PropTypes.shape({
    driver: PropTypes.string,
    translations: PropTypes.objectOf(PropTypes.string),
  }),
};

EditDriverModal.defaultProps = {
  editing: null,
};

export default EditDriverModal;
