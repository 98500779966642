import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import TextField from '@mui/material/TextField';
import { useFieldArray, useFormContext } from 'react-hook-form';

const CustomizeTranslationsLanguage = () => {
  const { control, register, watch } = useFormContext();
  const selectedLang = watch('selectedLang');
  const languages = watch('languages');
  const selectedLangIdx = languages.findIndex(lang => lang.language === selectedLang);
  const { fields } = useFieldArray({
    control,
    name: `languages.${selectedLangIdx}.translations`,
  });

  return (
    <Grid container>
      <Grid xs={12} sm={6} sx={{ p: 2, flex: 1 }}>
        {fields.map(row => (
          <TextField
            key={row.id}
            size="small"
            margin="normal"
            label={row.label}
            readOnly
            value={row.baseLang || row.baseDefault}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ backgroundColor: 'background.body' }}
          />
        ))}
      </Grid>
      <Grid xs={0} sm={0}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid xs={12} sm={6} sx={{ p: 2 }}>
        {fields.map((row, idx) => (
          <TextField
            key={`${row.id}-${selectedLang}`}
            {...register(`languages.${selectedLangIdx}.translations.${idx}.selectedLang`)}
            size="small"
            margin="normal"
            label={row.label}
            readOnly
            defaultValue={row.selectedDefault}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default CustomizeTranslationsLanguage;
