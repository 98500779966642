const getDriverLabel = (survey, id, formatMessage, locale) => {
  if (!survey) {
    return '';
  }
  const { driver } = survey?.drivers.find(d => d.driver._id === id) || {};
  if (!driver) {
    return id;
  }
  return (
    driver.translations?.[locale] ||
    driver.translations?.[survey.language] ||
    Object.values(driver.translations || {})[0] ||
    (driver.name && formatMessage({ id: `DriverPicklist.${driver.name}` })) ||
    id
  );
};

export default getDriverLabel;
