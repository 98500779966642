import DragIndicator from '@mui/icons-material/DragIndicator';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { arrayMoveImmutable } from 'client/util/arrayMove';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import React from 'react';

const SelectGroup = ({
  clearable = true,
  disabled,
  formatOptionLabel,
  getOptionValue,
  onAddNew,
  onChange,
  options,
  placeholder,
  value: selectedItems,
}) => {
  const onDragEnd = result => {
    if (result.destination) {
      onChange(arrayMoveImmutable(selectedItems, result.source.index, result.destination.index));
    }
  };

  const handleChange = idx => value => {
    const newItems = [...selectedItems];
    newItems[idx] = value;
    onChange(newItems);
  };

  const CustomMenu = ({ children, ...other }) => {
    return (
      <components.Menu {...other}>
        {children}
        {!onAddNew || (
          <Button fullWidth onClick={() => onAddNew({ name: other.selectProps.inputValue })}>
            <FormattedMessage id="SelectGroup.AddNew" defaultMessage="Add new" />
          </Button>
        )}
      </components.Menu>
    );
  };
  CustomMenu.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const SingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.valueLabel || props.data.label}
    </components.SingleValue>
  );
  SingleValue.propTypes = {
    data: PropTypes.object.isRequired,
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="options-list">
        {provided => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
            }}
          >
            {selectedItems?.map((value, idx) => (
              <Draggable
                key={getOptionValue(value) || '-'}
                draggableId={getOptionValue(value) || '-'}
                index={idx}
              >
                {provided2 => (
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 1,
                      gridTemplateColumns: '20px 1fr 40px',
                      alignItems: 'center',
                    }}
                    ref={provided2.innerRef}
                    {...provided2.draggableProps}
                  >
                    <Box sx={{ display: 'flex' }} {...provided2.dragHandleProps}>
                      <DragIndicator sx={{ color: '#bebeb8' }} />
                    </Box>
                    <Select
                      getOptionValue={getOptionValue}
                      formatOptionLabel={formatOptionLabel}
                      isMulti={false}
                      disabled={disabled}
                      value={value}
                      placeholder={placeholder}
                      options={options}
                      clearable={clearable}
                      components={{ Menu: CustomMenu, SingleValue }}
                      onChange={handleChange(idx)}
                      styles={{
                        menu: prov => ({ ...prov, zIndex: '999 !important' }),
                      }}
                    />
                    <IconButton onClick={() => onChange(selectedItems.filter((_, i) => i !== idx))}>
                      <RemoveCircleOutline />
                    </IconButton>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SelectGroup.propTypes = {
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onAddNew: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.array.isRequired,
};

SelectGroup.defaultProps = {
  clearable: true,
  disabled: false,
  formatOptionLabel: option => option.label,
  getOptionValue: option => option.value,
  onAddNew: null,
  placeholder: '',
};

export default SelectGroup;
