import { Add, Edit } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { setError, setOk } from 'client/modules/App/AppActions';
import EditDriverModal from 'client/modules/Surveys/pages/CustomizeSurvey/EditDriverModal';
import MailPreview from 'client/modules/Surveys/pages/CustomizeSurvey/MailPreview';
import {
  useCreateSurveyDriverMutation,
  useFindSurveyDriversQuery,
  useListDefaultQuery,
  useRemoveSurveyDriverMutation,
  useUpdateSurveyDriverMutation,
} from 'client/modules/Surveys/surveyDriverApiSlice';
import useOpenClose from 'client/util/useOpenClose';
import FormsyRadio from 'components/FormsyRadioGroup/FormsyRadio';
import FormsyRadioGroup from 'components/FormsyRadioGroup/FormsyRadioGroup';
import FormsyText from 'components/FormsyText/FormsyText';
import SelectField from 'components/SelectField/SelectField';
import SelectGroup from 'components/SelectGroup/SelectGroup';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { surveyLanguages } from 'Intl/setup';
import withLocation from '../../../../util/withLocation';
import { getToken } from '../../../Auth/AuthReducer';
import {
  customizationInputChange,
  groupInputChange,
  inputChange,
  postSurvey,
  setSurveySubmitEnabled,
} from '../../SurveyActions';
import {
  canSubmit,
  getCustomization,
  getCustomizations,
  getFilteredDrivers,
  getId,
  getInputValue,
  getLogoUrl,
  getRand,
  getSubmitButtonMessage,
  getSurveyLanguage,
  getSurveys,
  getSurveyType,
  surveyMessages,
} from '../../SurveyReducer';
import styles from './CustomizeEmail.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ignore = () => {};

const messages = defineMessages({
  surveySaved: {
    id: 'Survey.Saved',
    defaultMessage: 'Survey saved successfully',
  },
  configureDriverPicklistTooltip: {
    id: 'CustomizeFollowup.ConfigureDriverPicklistTooltip',
    defaultMessage: 'Configure the list of drivers available for the user to pick.',
  },
  addOption: {
    id: 'CustomizeFollowup.AddOption',
    defaultMessage: 'Add "{name}"',
  },
  defaultDrivers: {
    id: 'CustomizeFollowup.DefaultDrivers',
    defaultMessage: 'Default',
  },
  customDrivers: {
    id: 'CustomizeFollowup.CustomDrivers',
    defaultMessage: 'Custom',
  },
});
const languageItems = locale =>
  Object.keys(surveyLanguages[locale]).map(lang => (
    <MenuItem key={lang} value={lang}>
      {surveyLanguages[locale][lang]}
    </MenuItem>
  ));

function getScores(customizationGroup) {
  const [minScore, maxScore] = customizationGroup
    .match(/(\d)(\d*)/)
    .splice(1, 2)
    .map(Number);
  return { minScore, maxScore: maxScore || minScore };
}

const CustomizeFollowup = ({
  _id: surveyId,
  followupStrategy = 'static',
  customizationGroup,
  surveyType,
  showDrivers,
  drivers,
  intl: { formatMessage, locale },
  ...props
}) => {
  const baseLanguage = useSelector(getSurveyLanguage);
  const { data: availableDrivers = [], isSuccess: surveyDriversLoaded } = useFindSurveyDriversQuery(
    { surveyId },
  );
  const { data: defaultDrivers = [], isSuccess: defaultDriversLoaded } = useListDefaultQuery();

  const getOption = useCallback(
    kind =>
      ({ _id: driver, name, translations } = {}) => ({
        driver,
        label: translations?.[baseLanguage] || name,
        ...(followupStrategy === 'dynamic' ? getScores(customizationGroup) : {}),
        translations,
        kind,
      }),
    [customizationGroup, followupStrategy, baseLanguage],
  );

  const defaultOptions = useMemo(() => {
    return defaultDrivers.map(getOption('DefaultDriver'));
  }, [getOption, defaultDrivers]);

  const customOptions = useMemo(() => {
    return availableDrivers.map(getOption('SurveyDriver'));
  }, [getOption, availableDrivers]);

  const [createSurveyDriver] = useCreateSurveyDriverMutation();
  const [updateSurveyDriver] = useUpdateSurveyDriverMutation();
  const [removeSurveyDriver] = useRemoveSurveyDriverMutation();

  const [isDriverModalOpen, openDriverModal, closeDriverModal, editingDriver] = useOpenClose();
  const iframe = useRef();
  const [score] = useState('{score}');

  const handleSubmit = async () => {
    props.setSurveySubmitEnabled(false, formatMessage(surveyMessages['Survey.Submitting']));
    const { customizations = [], allDrivers } = props;
    const properties = {
      id: surveyId,
      customizations,
      drivers: allDrivers,
      showDrivers,
      followupStrategy,
    };
    await props.postSurvey(properties, props.token);
    props.setOk(formatMessage(messages.surveySaved), 4000);
  };

  const handleCustomizationChange = event => {
    props.customizationInputChange(event.target.id, event.target.value, props.surveyLanguage);
  };

  const handleShowDriversChange = (event, show) => {
    const newShowDrivers = [
      ...showDrivers.filter(s => (customizationGroup ? s.group !== customizationGroup : s.group)),
      { group: customizationGroup, show },
    ];
    if (drivers.length > 0) {
      props.groupInputChange(
        'drivers',
        drivers.map(d => ({ ...d, show })),
      );
    }
    props.inputChange('showDrivers', newShowDrivers);
  };

  const handleSelectChange = (name, value) => {
    props.groupInputChange(name, value);
  };

  const handleInputChange = (name, event) => {
    const { value } = event.target;
    props.inputChange(name, value);
  };

  const enableButton = () => {
    props.setSurveySubmitEnabled(true);
  };

  const disableButton = () => {
    props.setSurveySubmitEnabled(false);
  };

  const handleAddNew = useCallback(
    (translations, _id) => {
      if (_id) {
        updateSurveyDriver({ surveyId, _id, translations });
      } else {
        createSurveyDriver({
          surveyId,
          translations,
        }).then(({ data }) => {
          props.groupInputChange('drivers', [
            ...drivers.filter(d => d.label),
            getOption(customizationGroup)(data),
          ]);
        });
      }
    },
    [drivers, createSurveyDriver, customizationGroup],
  );

  const handleAddDriver = useCallback(() => {
    props.groupInputChange('drivers', [
      ...drivers,
      followupStrategy === 'dynamic' ? getScores(customizationGroup) : {},
    ]);
  }, [drivers, customizationGroup, props.groupInputChange, followupStrategy]);

  const handleRemove = useCallback(
    _id => {
      removeSurveyDriver({ surveyId, _id });
      closeDriverModal();
      props.groupInputChange(
        'drivers',
        drivers.filter(d => d._id !== _id),
      );
    },
    [surveyId, drivers, removeSurveyDriver, closeDriverModal, props.groupInputChange],
  );

  const lang = props.surveyLanguage || locale;
  const customizationGroups =
    surveyType === 'nps' ? ['0-3', '4-6', '7-8', '9-10'] : ['1-2', '3', '4-5'];
  const showDriverSection = !!showDrivers.find(
    d => (!d.group && !customizationGroup) || d.group === customizationGroup,
  )?.show;
  const driversValue = useMemo(
    () =>
      drivers.map(
        driver =>
          customOptions.find(o => o.driver === (driver?.driver?._id || driver?.driver)) ||
          defaultOptions.find(o => o.driver === (driver?.driver?._id || driver?.driver)) ||
          {},
      ),
    [drivers, customOptions, defaultOptions],
  );
  return (
    <>
      <Grid className={styles['main-grid']} style={props.style} fluid>
        <Row className={styles['main-content']}>
          <Col className={styles.controls} xs={12} sm={5} md={4} lg={4}>
            <Helmet title={formatMessage(surveyMessages['Survey.Personalize.Title'])} />
            <Formsy.Form
              onValid={enableButton}
              onInvalid={disableButton}
              onValidSubmit={handleSubmit}
            >
              <Paper className={styles.form}>
                <Box className={styles.formInner}>
                  <FormsyRadioGroup
                    fullWidth
                    id="followupStrategy"
                    name="followupStrategy"
                    value={followupStrategy}
                    onChange={handleInputChange.bind(null, 'followupStrategy')}
                    className={styles.strategy}
                  >
                    <FormsyRadio
                      label={formatMessage(surveyMessages['Survey.FollowupPageStatic'])}
                      value="static"
                    />
                    <FormsyRadio
                      label={formatMessage(surveyMessages['Survey.FollowupPageDynamic'])}
                      value="dynamic"
                    />
                    <FormsyRadio
                      label={formatMessage(surveyMessages['Survey.FollowupPageNone'])}
                      value="none"
                    />
                  </FormsyRadioGroup>

                  {followupStrategy === 'dynamic' ? (
                    <ToggleButtonGroup
                      sx={{ mt: 2 }}
                      fullWidth
                      id="customizationGroup"
                      label={formatMessage(surveyMessages['Survey.FollowupPageCustomizationGroup'])}
                      value={customizationGroup || locale}
                      exclusive
                      onChange={handleInputChange.bind(null, 'customizationGroup')}
                    >
                      {customizationGroups.map(sc => {
                        const value = `group${sc.replace('-', '')}`;
                        return (
                          <ToggleButton key={value} value={value}>
                            {sc}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  ) : null}
                  {followupStrategy !== 'none' && (
                    <>
                      <Box sx={{ width: '100%' }}>
                        <SelectField
                          label={formatMessage(surveyMessages['Survey.Language'])}
                          id="surveyLanguage"
                          sx={{ color: 'action.active' }}
                          disableUnderline
                          value={lang}
                          onChange={handleInputChange.bind(null, 'surveyLanguage')}
                        >
                          {languageItems(locale)}
                        </SelectField>
                      </Box>
                      <FormsyText
                        fullWidth
                        id="followUpQuestion"
                        name="followUpQuestion"
                        value={props.followUpQuestion}
                        multiLine
                        rows={3}
                        hintText={formatMessage(surveyMessages['nps.tell_us_more'])}
                        label={formatMessage(surveyMessages['Survey.FollowUpQuestion'])}
                        onChange={handleCustomizationChange}
                      />

                      <FormControlLabel
                        sx={{ my: 2, alignSelf: 'flex-start' }}
                        control={
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={showDriverSection}
                            onChange={handleShowDriversChange}
                          />
                        }
                        label={
                          <FormattedMessage
                            id="CustomizeFollowup.ShowDriverPicklist"
                            defaultMessage="Show driver picklist"
                          />
                        }
                      />

                      {!surveyDriversLoaded || !defaultDriversLoaded || !showDriverSection || (
                        <>
                          <SelectGroup
                            getOptionValue={option => option.driver}
                            formatOptionLabel={option => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {option.label}
                                {option.kind !== 'SurveyDriver' || (
                                  <IconButton size="small" onClick={() => openDriverModal(option)}>
                                    <Edit />
                                  </IconButton>
                                )}
                              </Box>
                            )}
                            onAddNew={openDriverModal}
                            fullWidth
                            id="drivers"
                            value={driversValue}
                            onChange={handleSelectChange.bind(null, 'drivers')}
                            className={styles.group}
                            options={[
                              {
                                label: formatMessage(messages.defaultDrivers),
                                options: defaultOptions,
                              },
                              {
                                label: formatMessage(messages.customDrivers),
                                options: customOptions,
                              },
                            ]}
                          />
                          <Button
                            startIcon={<Add />}
                            sx={{ alignSelf: 'flex-start' }}
                            onClick={handleAddDriver}
                          >
                            <FormattedMessage
                              id="SelectGroup.Add driver"
                              defaultMessage="Add driver"
                            />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    className={styles.submit}
                    disabled={!props.canSubmit}
                    color="primary"
                  >
                    {props.submitButtonMessage || surveyId || props.surveys.length > 0
                      ? formatMessage(surveyMessages['Survey.Save'])
                      : formatMessage(surveyMessages['Survey.Next'])}
                  </Button>
                </Box>
              </Paper>
            </Formsy.Form>
          </Col>
          <Col xs={12} sm={7} md={8} lg={8}>
            {followupStrategy !== 'none' && (
              <MailPreview
                preview
                key={lang}
                score={score}
                ref={iframe}
                onClick={ignore}
                locale={lang}
                brand={props.brand}
                topColor={props.topColor}
                buttonColor={props.buttonColor}
                surveyLanguage={props.surveyLanguage}
                logoUrl={props.logoUrl}
                rand={props.rand}
                onSubmit={ignore}
                followUpQuestion={props.followUpQuestion}
                drivers={driversValue}
              />
            )}
          </Col>
        </Row>
      </Grid>
      <EditDriverModal
        editing={editingDriver}
        onSubmit={handleAddNew}
        availableDrivers={availableDrivers}
        drivers={drivers}
        onClose={closeDriverModal}
        isOpen={isDriverModalOpen}
        onRemove={handleRemove}
      />
    </>
  );
};

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
  const surveyType = getSurveyType(state) || ownProps.location.query.type || 'nps';
  const _id = getId(state);
  const buttonColor =
    getInputValue('buttonColor', state) ||
    (!_id && surveyType === 'star_five' ? '#f6d400' : '#333');
  const topColor = getInputValue('topColor', state) || '#333';
  return {
    _id,
    surveyType,
    canSubmit: canSubmit(state),
    topColor,
    buttonColor,
    brand: getInputValue('brand', state),
    showDrivers: getInputValue('showDrivers', state),
    surveyLanguage: getInputValue('surveyLanguage', state),
    token: getToken(state),
    submitButtonMessage: getSubmitButtonMessage(state),
    surveys: getSurveys(state),
    logoUrl: getLogoUrl(state),
    rand: `${getRand(state)}`,
    customizations: getCustomizations(state),
    allDrivers: getInputValue('drivers', state),
    drivers: getFilteredDrivers(state) || [''],
    followUpQuestion: getCustomization('followUpQuestion', state, state.surveys.followupStrategy),
    followupStrategy: getInputValue('followupStrategy', state),
    customizationGroup: getInputValue('customizationGroup', state),
    preventMultipleResponses: getInputValue('preventMultipleResponses', state) || false,
  };
}

CustomizeFollowup.propTypes = {
  _id: PropTypes.string,
  brand: PropTypes.string,
  buttonColor: PropTypes.string,
  canSubmit: PropTypes.bool,
  customizations: PropTypes.array,
  followUpQuestion: PropTypes.string,
  customizationGroup: PropTypes.string,
  followupStrategy: PropTypes.string,
  inputChange: PropTypes.func,
  groupInputChange: PropTypes.func,
  logoUrl: PropTypes.string,
  postSurvey: PropTypes.func,
  rand: PropTypes.string,
  setOk: PropTypes.func,
  setSurveySubmitEnabled: PropTypes.func,
  showDrivers: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      show: PropTypes.bool.isRequired,
    }),
  ),
  submitButtonMessage: PropTypes.string,
  surveyLanguage: PropTypes.string,
  surveys: PropTypes.array,
  token: PropTypes.string,
  topColor: PropTypes.string,
  style: PropTypes.object,
  surveyType: PropTypes.string,
  drivers: PropTypes.array,
  allDrivers: PropTypes.array,
  intl: PropTypes.object.isRequired,
  customizationInputChange: PropTypes.func.isRequired,
};

CustomizeFollowup.defaultProps = {
  _id: '',
  brand: '',
  buttonColor: '',
  canSubmit: false,
  customizations: [],
  followUpQuestion: '',
  customizationGroup: '',
  followupStrategy: '',
  inputChange: () => {},
  groupInputChange: () => {},
  logoUrl: '',
  postSurvey: () => {},
  rand: '',
  setOk: () => {},
  setSurveySubmitEnabled: () => {},
  showDrivers: [],
  submitButtonMessage: '',
  surveyLanguage: '',
  surveys: [],
  token: '',
  topColor: '',
  style: {},
  surveyType: '',
  drivers: [],
  allDrivers: [],
};

const mapDispatchToProps = dispatch => ({
  setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage) {
    dispatch(setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage));
  },
  postSurvey(model, token, navigate, shouldStay) {
    dispatch(postSurvey(model, token, navigate, shouldStay));
  },
  setError(msg) {
    dispatch(setError(msg));
  },
  setOk(msg, timeout) {
    dispatch(setOk(msg, timeout));
  },
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
  groupInputChange(id, value) {
    dispatch(groupInputChange(id, value));
  },
  customizationInputChange(name, value, language) {
    dispatch(customizationInputChange(name, value, language, false, true));
  },
});

export default injectIntl(
  withLocation(connect(mapStateToProps, mapDispatchToProps)(CustomizeFollowup)),
);
