//* * Redux toolkit slice for surveyDriver API calls */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';

export const surveyDriverApiSlice = createApi({
  reducerPath: 'surveyDriverReducer',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['SurveyDriver', 'DefaultDriver'],
  endpoints: builder => ({
    listDefault: builder.query({
      query: () => ({
        url: 'surveys/drivers',
        type: 'DefaultDriver',
        providesTags: defaultDrivers => {
          return [
            ...(defaultDrivers || []).map(({ _id: id }) => ({
              type: 'DefaultDriver',
              id,
            })),
            {
              type: 'DefaultDriver',
              id: 'LIST',
            },
          ];
        },
      }),
    }),
    createDefaultSurveyDrivers: builder.mutation({
      query: ({ surveyId }) => ({
        url: `/surveys/${surveyId}/drivers/default`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'SurveyDriver', id: 'LIST' }],
    }),
    createSurveyDriver: builder.mutation({
      query: ({ surveyId, translations }) => ({
        url: `/surveys/${surveyId}/drivers`,
        method: 'POST',
        body: { translations },
      }),
      invalidatesTags: [{ type: 'SurveyDriver', id: 'LIST' }],
    }),
    updateSurveyDriver: builder.mutation({
      query: ({ surveyId, _id, translations }) => ({
        url: `/surveys/${surveyId}/drivers/${_id}`,
        method: 'PATCH',
        body: { translations },
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [
          {
            type: 'SurveyDriver',
            id: _id,
          },
        ];
      },
    }),
    removeSurveyDriver: builder.mutation({
      query: ({ surveyId, _id }) => ({
        url: `/surveys/${surveyId}/drivers/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [
          {
            type: 'SurveyDriver',
            id: _id,
          },
        ];
      },
    }),
    findSurveyDrivers: builder.query({
      query: ({ surveyId }) => `/surveys/${surveyId}/drivers`,
      type: 'SurveyDriver',
      providesTags: surveyDrivers => {
        return [
          ...(surveyDrivers || []).map(({ _id: id }) => ({
            type: 'SurveyDriver',
            id,
          })),
          {
            type: 'SurveyDriver',
            id: 'LIST',
          },
        ];
      },
    }),
    addScoreRangeToSurveyDriver: builder.mutation({
      query: ({ surveyId, _id, scoreRange }) => ({
        url: `/surveys/${surveyId}/drivers/${_id}/scoreRange`,
        method: 'POST',
        body: { scoreRange },
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [
          {
            type: 'SurveyDriver',
            id: _id,
          },
        ];
      },
    }),
    removeScoreRangeFromSurveyDriver: builder.mutation({
      query: ({ surveyId, _id, scoreRangeId }) => ({
        url: `/surveys/${surveyId}/drivers/${_id}/scoreRange/${scoreRangeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [
          {
            type: 'SurveyDriver',
            id: _id,
          },
        ];
      },
    }),
  }),
});

export const {
  useListDefaultQuery,
  useCreateDefaultSurveyDriversMutation,
  useCreateSurveyDriverMutation,
  useUpdateSurveyDriverMutation,
  useRemoveSurveyDriverMutation,
  useFindSurveyDriversQuery,
  reducer: surveyDriverReducer,
} = surveyDriverApiSlice;
