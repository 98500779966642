import { PlayArrow, Replay } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { setError as setErrorAction } from 'client/modules/App/AppActions';
import AdditionalQuestionPage from 'client/modules/Response/pages/AddResponsePages/AdditionalQuestionPage';
import { ThankyouPageDisconnected } from 'client/modules/Response/pages/AddResponsePages/ThankyouPage';
import CustomizePreviewModal from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizePreviewModal';
import DraftActions from 'client/modules/Surveys/pages/CustomizeSurvey/DraftActions';
import EditQuestionTabs from 'client/modules/Surveys/pages/CustomizeSurvey/EditQuestionTabs';
import FreeResponseTypes from 'client/modules/Surveys/pages/CustomizeSurvey/FreeResponseTypes';
import ListQuestions from 'client/modules/Surveys/pages/CustomizeSurvey/ListQuestions';
import MailPreview from 'client/modules/Surveys/pages/CustomizeSurvey/MailPreview';
import onMailPreviewClick from 'client/modules/Surveys/pages/CustomizeSurvey/onMailPreviewClick';
import {
  freeResponse,
  selectMany,
  selectOne,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import {
  addQuestion as addQuestionAction,
  deleteQuestion as deleteQuestionAction,
  editQuestion as editQuestionAction,
  getNextQuestion as getNextQuestionAction,
  moveQuestion as moveQuestionAction,
} from 'client/modules/Surveys/SurveyActions';
import {
  getAdditionalQuestions,
  getCustomization,
  getFilteredDrivers,
  getId,
  getPreviewPagesProps,
  getSurvey,
  getSurveyType,
  surveyMessages,
} from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Sticky from 'react-stickynode';
import { surveyLanguages } from 'Intl/setup';

const messages = defineMessages({
  configurePreview: {
    id: 'AdditionalQuestions.ConfigurePreview',
    defaultMessage:
      'Configure the person properties to use to preview the survey. In this way you can test the flow for different audiences.',
  },
  restartPreviewTooltip: {
    id: 'AdditionalQuestions.RestartPreviewTooltip',
    defaultMessage:
      'See the survey from the beginning as if you were the person with the properties you selected.',
  },
  restartPreview: {
    id: 'AdditionalQuestions.RestartPreview',
    defaultMessage: 'Restart preview',
  },
  startPreview: {
    id: 'AdditionalQuestions.StartPreview',
    defaultMessage: 'Start preview',
  },
});

const languageItems = locale =>
  Object.keys(surveyLanguages[locale]).map(lang => (
    <MenuItem key={lang} value={lang}>
      {surveyLanguages[locale][lang]}
    </MenuItem>
  ));

const AdditionalQuestions = () => {
  const [previewKey, setPreviewKey] = useState(0);
  const { locale } = useIntl();
  const surveyId = useSelector(getId);
  const survey = useSelector(state => getSurvey(state, surveyId));
  const [language, setLanguage] = useState(survey.language);
  const [previewDone, setPreviewDone] = useState(false);
  const [thankYouCustomizations, setThankYouCustomizations] = useState({});
  const previewPagesProps = useSelector(getPreviewPagesProps);
  const [personProperties, setPersonProperties] = useState({});
  const [score, setScore] = useState(null);
  const followUpQuestion = useSelector(state =>
    getCustomization('followUpQuestion', state, state.surveys.followupStrategy, language, score),
  );
  const drivers = useSelector(state => getFilteredDrivers(state, score));
  const followupStrategy = useSelector(state => state.surveys.followupStrategy);
  const [isPreviewModalOpen, openPreviewModal, closePreviewModal] = useOpenClose();
  const setError = useActions(setErrorAction);
  const [previewQuestionIdx, setPreviewQuestionIdx] = useState(0);
  const { formatMessage } = useIntl();
  const surveyType = useSelector(getSurveyType);
  const [addQuestion, deleteQuestion, editQuestion, moveQuestion] = useActions([
    addQuestionAction,
    deleteQuestionAction,
    editQuestionAction,
    moveQuestionAction,
  ]);
  const [editing, setEditing] = useState(false);
  const handleOpenConfirm = useActions(openConfirm);
  const additionalQuestions = useSelector(getAdditionalQuestions) || [];
  const previewQuestion = additionalQuestions[previewQuestionIdx];
  const questionForm = useForm({
    defaultValues: editing || previewQuestion,
    reValidateMode: 'onChange',
  });
  const formValues = questionForm.watch();
  const getNextQuestion = useActions(getNextQuestionAction);

  useEffect(() => {
    questionForm.reset(editing || previewQuestion);
  }, [editing, previewQuestion]);
  const handleEdit = (question, index) => {
    setEditing(question);
    setPreviewQuestionIdx(index);
  };
  const handleAdd = (type = freeResponse) => {
    setEditing({
      type,
      freeResponseType: FreeResponseTypes.shortText,
      text: '',
      intro: null,
      description: null,
      range: 5,
      style: 'numerical',
      scaleMin: 1,
      scaleMax: 5,
      buttonLabel: formatMessage(surveyMessages['Survey.NextQuestion']),
      scaleMinLabel: formatMessage(surveyMessages['csat.bottom']),
      scaleMaxLabel: formatMessage(surveyMessages['csat.top']),
      optionsAttributes: [],
    });
  };
  const handleDelete = question => {
    handleOpenConfirm({
      title: (
        <FormattedMessage
          id="AdditionalQuestions.ConfirmDelete"
          defaultMessage="Are you sure you want to delete this question?"
        />
      ),
      text: question.text,
      onOk: async () => {
        await deleteQuestion(surveyId, question._id);
      },
      textOk: (
        <FormattedMessage
          id="AdditionalQuestions.Delete question"
          defaultMessage="Delete question"
        />
      ),
      danger: true,
    });
  };

  const handleMove = (from, to) => {
    moveQuestion(surveyId, from, to);
  };

  const handleSave = async question => {
    try {
      if (!question.text) {
        setError(
          <FormattedMessage
            id="AdditionalQuestions.Errors.NoText"
            defaultMessage="The the question can't be blank"
          />,
        );
        return;
      }
      if (
        [selectOne, selectMany].includes(question.type) &&
        question.optionsAttributes?.length < 2
      ) {
        setError(
          <FormattedMessage
            id="AdditionalQuestions.Errors.NoOptions"
            defaultMessage="You should include at least 2 options"
          />,
        );
        return;
      }
      setError('');
      if (question._id) {
        await editQuestion(surveyId, question._id, question);
      } else {
        await addQuestion(surveyId, question);
      }
    } catch (e) {
      setError(e.message);
    }
    setEditing(null);
  };
  const handleCancel = () => {
    setEditing(null);
  };
  const handlePreview = element => {
    setPreviewQuestionIdx(element.dataset.index);
  };
  const restartPreview = async () => {
    setPreviewDone(true);
    setPreviewKey(k => k + 1);
    if (surveyType === 'custom') {
      const res = await getNextQuestion(surveyId, score, personProperties, language);
      setScore(0);
      setPreviewQuestionIdx(res.nextQuestion);
      setThankYouCustomizations(res.thankYouCustomizations);
    } else {
      setScore(null);
      setPreviewQuestionIdx('start');
    }
  };
  const handleSubmitFollowUp = async () => {
    const res = await getNextQuestion(surveyId, score, personProperties, language);
    setPreviewQuestionIdx(res.nextQuestion);
    setThankYouCustomizations(res.thankYouCustomizations);
  };

  return (
    <Grid
      container
      spacing={4}
      sx={{
        width: '100%',
        maxWidth: 1280,
        alignSelf: 'center',
        my: 1,
        minHeight: 'calc(100vh - 250px)',
      }}
    >
      <Helmet title={formatMessage(surveyMessages['Survey.Personalize.Title'])} />
      <FormProvider {...questionForm}>
        <Grid xs={12} md={6}>
          <Typography variant="h1">
            <FormattedMessage
              id="AdditionalQuestions.TitleOff"
              defaultMessage="Additional questions"
            />
          </Typography>
          <Typography sx={{ mb: 4 }}>
            <FormattedMessage
              id="AdditionalQuestions.subtitle"
              defaultMessage="Optional questions that will be displayed after the {type} survey."
              values={{
                type: surveyType.replace('star_five', '').toUpperCase(),
              }}
            />
          </Typography>
          {editing ? (
            <EditQuestionTabs
              onSave={handleSave}
              onCancel={handleCancel}
              additionalQuestions={additionalQuestions}
              questionIndex={previewQuestionIdx}
            />
          ) : (
            <ListQuestions
              onAdd={handleAdd}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onMove={handleMove}
              onPreview={handlePreview}
            />
          )}
          <DraftActions hide={editing} />
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              minHeight: 40,
            }}
          >
            {!editing && (
              <>
                <Tooltip title={formatMessage(messages.restartPreviewTooltip)}>
                  <Button
                    type="text"
                    onClick={() => restartPreview()}
                    startIcon={previewDone ? <Replay /> : <PlayArrow />}
                  >
                    {formatMessage(previewDone ? messages.restartPreview : messages.startPreview)}
                  </Button>
                </Tooltip>
                <Select
                  sx={{ color: 'action.active' }}
                  disableUnderline
                  variant="standard"
                  maxHeight={200}
                  value={language}
                  onChange={e => {
                    setLanguage(e.target.value);
                  }}
                >
                  {languageItems(locale)}
                </Select>
                <Tooltip title={formatMessage(messages.configurePreview)}>
                  <Button onClick={openPreviewModal} sx={{ color: 'action.active' }}>
                    <FormattedMessage
                      id="AdditionalQuestions.ConfigureProperties"
                      defaultMessage="Configure properties"
                    />
                  </Button>
                </Tooltip>
              </>
            )}
          </Box>

          {(() => {
            if (editing || previewQuestion) {
              return (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Sticky bottomBoundary="#content" enabled={editing}>
                    <Paper
                      sx={{
                        minHeight: 400,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <AdditionalQuestionPage
                        key={previewKey}
                        preview
                        question={editing ? formValues : null}
                        questionIndex={previewQuestionIdx}
                        hasNext={previewQuestionIdx < additionalQuestions.length - 1}
                        previewProps={{
                          editing: !!editing,
                          questionIndex: previewQuestionIdx,
                          setQuestionIndex: setPreviewQuestionIdx,
                          score,
                          personProperties,
                        }}
                        language={language}
                      />
                    </Paper>
                    <Box sx={{ height: 10 }} />
                  </Sticky>
                </Box>
              );
            }
            if (previewQuestionIdx === 'thanks') {
              return (
                <Paper
                  sx={{
                    width: '100%',
                    minHeight: 400,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ThankyouPageDisconnected
                    preview
                    {...previewPagesProps}
                    {...thankYouCustomizations}
                    language={language}
                  />
                </Paper>
              );
            }
            if (previewQuestionIdx === 'start') {
              return (
                <MailPreview
                  fullWidth
                  {...previewPagesProps}
                  surveyLanguage={language}
                  score={score}
                  onClick={onMailPreviewClick(setScore)}
                  onSubmit={handleSubmitFollowUp}
                  followUpQuestion={followUpQuestion}
                  drivers={drivers?.map(driver => (driver.driver._id ? driver.driver : driver))}
                  followupStrategy={followupStrategy}
                />
              );
            }
            return (
              <Paper
                sx={{
                  width: '100%',
                  minHeight: 400,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  color="muted.main"
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FormattedMessage
                    id="AdditionalQuestions.AddOnTheLeft"
                    defaultMessage="Add a question to the list on the left"
                  />
                </Box>
              </Paper>
            );
          })()}
        </Grid>
      </FormProvider>
      <CustomizePreviewModal
        score={score}
        setScore={setScore}
        onClose={closePreviewModal}
        isOpen={isPreviewModalOpen}
        personProperties={personProperties}
        setPersonProperties={setPersonProperties}
      />
    </Grid>
  );
};

export default AdditionalQuestions;
