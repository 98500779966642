import { handleActions } from 'redux-actions';

// Import Actions

// Initial State
const initialState = {
  tokenValid: null,
  score: null,
  comment: null,
  typing: false,
  responses: {},
  topColor: '#333',
  buttonColor: '#333',
  message: null,
  thankyouTitle: null,
  linkText: null,
  linkURL: null,
  fetchError: null,
  allTags: null,
  loaded: false,
  alreadyResponded: false,
  archived: false,
  disabled: false,
};

const addResponseCb = (state, action) => ({
  ...state,
  tokenValid: action.payload.valid,
  score: action.payload.score,
  language: action.payload.language,
  brand: action.payload.brand,
  color: action.payload.color,
  topColor: action.payload.topColor,
  buttonColor: action.payload.buttonColor,
  message: action.payload.thankyouMessage,
  thankyouTitle: action.payload.thankyouTitle,
  linkText: action.payload.thankyouLinkText,
  linkURL: action.payload.thankyouLinkURL,
  survey: action.payload.survey,
  client: action.payload.client,
  clientHash: action.payload.clientHash,
  firstResponse: action.payload.firstResponse,
  logoUrl: action.payload.logoUrl
    ? action.payload.logoUrl.replace(/\?$/, "")
    : null,
  typing: false,
  question: action.payload.question,
  intro: action.payload.intro,
  person: action.payload.person,
  surveyType: action.payload.surveyType,
  surveyCsatType: action.payload.surveyCsatType,
  followUpQuestion: action.payload.followUpQuestion,
  loaded: true,
  alreadyResponded: action.payload.alreadyResponded || false,
  additionalQuestions: action.payload.additionalQuestions || false,
  disabled: action.payload.disabled || false,
  archived: action.payload.archived || false,
  nextQuestion: action.payload.nextQuestion,
  drivers: action.payload.drivers,
  followupStrategy: action.payload.followupStrategy,
});
export const ResposeReducer = handleActions(
  {
    ADD_RESPONSE: (state, action) => ({
      ...state,
      alreadyResponded: false,
      score: action.payload.score,
      comment: action.payload.comment,
    }),
    ALREADY_RESPONDED_CB: (state, action) => {
      if (action.payload) {
        return addResponseCb(state, {
          ...action,
          payload: {
            ...action.payload,
            alreadyResponded: true,
          },
        });
      }
      return { ...state, alreadyResponded: true };
    },
    NOT_FOUND_CB: (state, action) => {
      if (action.payload) {
        return addResponseCb(state, {
          ...action,
          payload: {
            ...action.payload,
            notFound: true,
          },
        });
      }
      return { ...state, notFound: true };
    },
    DISABLED_CB: (state, action) => {
      if (action.payload) {
        return addResponseCb(state, {
          ...action,
          payload: {
            ...action.payload,
            disabled: true,
          },
        });
      }
      return { ...state, disabled: true };
    },
    ADD_RESPONSE_CB: addResponseCb,
    TEXTAREA_CHANGE: (state, action) => ({
      ...state,
      typing: action.payload.comment.length > 0,
      comment: action.payload.comment,
    }),
    FETCH_TAGS_CB: (state, action) => ({
      ...state,
      allTags: action.payload,
    }),
    FETCH_RESPONSE_CB: (state, action) => ({
      ...state,
      responses: { ...state.responses, [action.payload.id]: action.payload },
      fetchError: null,
    }),
    FETCH_RESPONSE_ERROR_CB: (state, action) => ({
      fetchError: action.payload,
    }),
    DELETE_RESPONSE_CB: (state, action) => ({
      ...state,
      responses: { ...state.responses, [action.payload.id]: undefined },
    }),
    SET_TAGS_CB: (state, action) => {
      const response = { ...state.responses[action.payload.id], tags: action.payload.tags };
      return {
        ...state,
        responses: { ...state.responses, [action.payload.id]: response },
      };
    },
  },
  initialState,
);
/* Selectors */

// Is the received token valid?
export const getTokenValid = state => state.responses.tokenValid;
export const getScore = state => state.responses.score;
export const getBrand = state => state.responses.brand;
export const getLogoUrl = state => state.responses.logoUrl;
export const getTopColor = state => state.responses.topColor;
export const getButtonColor = state => state.responses.buttonColor;
export const getLanguage = state => state.responses.language;
export const getComment = state => state.responses.comment;
export const getTyping = state => state.responses.typing;
export const getMessage = state => state.responses.message;
export const getThankyouTitle = state => state.responses.thankyouTitle;
export const getLinkText = state => state.responses.linkText;
export const getLinkURL = state => state.responses.linkURL;
export const getClient = state => state.responses.client;
export const getClientHash = state => state.responses.clientHash;
export const getSurvey = state => state.responses.survey;
export const getFirstResponse = state => state.responses.firstResponse;
export const getResponse = responseId => state =>
  state.responses.responses && state.responses.responses[responseId];
export const isResponseLoaded = responseId => state => getResponse(responseId)(state) !== undefined;
export const getFetchError = state => state.responses.fetchError;
export const getQuestion = state => state.responses.question;
export const getIntro = state => state.responses.intro;
export const getPerson = state => state.responses.person;
export const getSurveyType = state => state.responses.surveyType;
export const getSurveyCsatType = state => state.responses.surveyCsatType;
export const getCsatFacesNum = state => state.responses.csatFacesNum;
export const getAllTags = state => state.responses.allTags;
export const getFollowUpQuestion = state => state.responses.followUpQuestion;
export const getFollowupStrategy = state => state.responses.followupStrategy;
export const isAlreadyResponded = state => state.responses.alreadyResponded;
export const isDisabled = state => state.responses?.disabled || state.responses?.archived;
export const getAdditionalQuestions = state => state.responses.additionalQuestions;
export const getNextQuestion = state => state.responses.nextQuestion;
export const getDrivers = state => state.responses.drivers;

// Export Reducer
export default ResposeReducer;
