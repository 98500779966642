import getTimeZoneOffset from 'client/util/getTimeZoneOffset';
import isNil from 'lodash/isNil';
import promiseRetry from 'promise-retry';
import callApi from '../../util/apiCaller';
import uploadSignedS3 from '../../util/uploadSignedS3';
import { setError } from '../App/AppActions';
import { getToken } from '../Auth/AuthReducer';

// Export Constants
export const ADD_SURVEY = 'ADD_SURVEY';
export const TOGGLE_TOP_PICKER = 'TOGGLE_TOP_PICKER';
export const TOGGLE_BUTTON_PICKER = 'TOGGLE_BUTTON_PICKER';
export const SURVEY_INPUT_CHANGE = 'SURVEY_INPUT_CHANGE';
export const GROUP_SURVEY_INPUT_CHANGE = 'FILTERED_SURVEY_INPUT_CHANGE';
export const CUSTOMIZATION_INPUT_CHANGE = 'CUSTOMIZATION_INPUT_CHANGE';
export const SET_SURVEY_SUBMIT_STATE = 'SET_SURVEY_SUBMIT_STATE';
export const SURVEY_STORED = 'SURVEY_STORED';
export const SET_PEOPLE_INPUT = 'SET_PEOPLE_INPUT';
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';
export const UPLOAD_DONE = 'UPLOAD_DONE';
export const UPLOAD_START = 'UPLOAD_START';
export const ADD_PEOPLE = 'ADD_PEOPLE';
export const SCHEDULE_SENDING_DONE = 'SCHEDULE_SENDING_DONE';
export const SOCKET_TYPING = 'SOCKET_TYPING';
export const SOCKET_STOP_TYPING = 'SOCKET_STOP_TYPING';
export const SOCKET_LISTEN = 'SOCKET_LISTEN';
export const SOCKET_NEW_RESPONSE = 'SOCKET_NEW_RESPONSE';
export const SOCKET_STOP_LISTEN = 'SOCKET_STOP_LISTEN';
export const BC_NEW_RESPONSE = 'BC_NEW_RESPONSE';
export const BC_TYPING = 'BC_TYPING';
export const BC_STOP_TYPING = 'BC_STOP_TYPING';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const LOAD_INPUT_VALUES = 'LOAD_INPUT_VALUES';
export const RELOAD_SEARCH = 'RELOAD_SEARCH';
export const NEW_SURVEY = 'NEW_SURVEY';
export const DELETE_SURVEY_DONE = 'DELETE_SURVEY_DONE';
export const TOGGLE_CHART = 'TOGGLE_CHART';
export const SWITCH_SURVEY = 'SWITCH_SURVEY';
export const TIME_SERIES_DONE = 'TIME_SERIES_DONE';
export const SET_CHART_PERIOD = 'SET_CHART_PERIOD';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const TOGGLE_SHOW_ENGAGEMENT = 'TOGGLE_SHOW_ENGAGEMENT';
export const CUSTOMIZE_SURVEY_CHANGE_TAB = 'CUSTOMIZE_SURVEY_CHANGE_TAB';
export const SET_TRANSLITERATE = 'SET_TRANSLITERATE';
export const BC_IMPORT_DONE = 'BC_IMPORT_DONE';
export const BC_IMPORT_CSV_UPLOADED = 'BC_IMPORT_CSV_UPLOADED';
export const BC_IMPORT_INPUT_UPLOADED = 'BC_IMPORT_INPUT_UPLOADED';
export const BC_IMPORT_ERROR = 'BC_IMPORT_ERROR';
export const RESET_PEOPLE = 'RESET_PEOPLE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CLEAR_PEOPLE = 'CLEAR_PEOPLE';
export const WEB_SNIPPET_STATUS_CB = 'WEB_SNIPPET_STATUS_CB';
export const UPDATE_SURVEY_CB = 'UPDATE_SURVEY_CB';
export const EXPORT_RESULTS_CB = 'EXPORT_RESULTS_CB';
export const EXPORT_RESULTS_LIST_PENDING = 'EXPORT_RESULTS_LIST_PENDING';
export const EXPORT_RESULTS_LIST_ERROR = 'EXPORT_RESULTS_LIST_ERROR';
export const EXPORT_RESULTS_LIST_CB = 'EXPORT_RESULTS_LIST_CB';
export const BC_EXPORT_PROGRESS = 'BC_EXPORT_PROGRESS';
export const BC_EXPORT_DONE = 'BC_EXPORT_DONE';
export const BC_EXPORT_ERROR = 'BC_EXPORT_ERROR';
export const CLEAR_EXPORT = 'CLEAR_EXPORT';
// Export Actions

export function toggleShowEngagement(showEngagement) {
  return {
    type: TOGGLE_SHOW_ENGAGEMENT,
    showEngagement,
  };
}

export function fileUploaded(url) {
  return {
    type: FILE_UPLOADED,
    url,
  };
}

export function reloadSearch() {
  return {
    type: RELOAD_SEARCH,
  };
}

export function addSurveys(surveys) {
  return {
    type: ADD_SURVEY,
    surveys,
  };
}

export function toggleTopPicker(show) {
  return {
    type: TOGGLE_TOP_PICKER,
    show,
  };
}

export function toggleButtonPicker(show) {
  return {
    type: TOGGLE_BUTTON_PICKER,
    show,
  };
}

export function inputChange(id, value) {
  return {
    type: SURVEY_INPUT_CHANGE,
    id,
    value,
  };
}

export function groupInputChange(id, value) {
  return {
    type: GROUP_SURVEY_INPUT_CHANGE,
    id,
    value,
  };
}

export function customizationInputChange(name, value, language, thankYou, followup) {
  return {
    type: CUSTOMIZATION_INPUT_CHANGE,
    name,
    value,
    language,
    thankYou,
    followup,
  };
}

export function setSurveySubmitEnabled(canSubmit, submitButtonMessage) {
  return {
    type: SET_SURVEY_SUBMIT_STATE,
    canSubmit,
    submitButtonMessage,
  };
}

export function surveyStored(survey, wasNew) {
  return {
    type: SURVEY_STORED,
    survey,
    wasNew,
  };
}
export function postSurvey(model, token, navigate, shouldStay) {
  return dispatch => {
    return callApi(dispatch, 'surveys', token, 'post', model).then(res => {
      dispatch(surveyStored(res, isNil(model.id)));
      if (typeof navigate === 'function') {
        if (shouldStay) {
          navigate(`/surveys/${res.id}/edit`, { replace: true });
        } else if (model.id) {
          navigate(`/surveys/${res.id}`);
        } else {
          navigate(`/surveys/${res.id}/import`);
        }
      }
    });
  };
}

export function setPeopleInput(peopleInput) {
  return dispatch => {
    dispatch({
      type: SET_PEOPLE_INPUT,
      peopleInput,
    });
    return Promise.resolve();
  };
}

export function setUploadFile(file) {
  return dispatch => {
    dispatch({
      type: SET_UPLOAD_FILE,
      file,
    });
    return Promise.resolve();
  };
}

export function uploadStart(surveyId) {
  return {
    type: UPLOAD_START,
    surveyId,
  };
}

export function uploadDone(surveyId, status, importId, totalPeople) {
  return {
    type: UPLOAD_DONE,
    surveyId,
    status,
    importId,
    totalPeople,
  };
}

export function signS3Import(survey, fileName, contentType, token) {
  return dispatch => {
    const endpoint = `surveys/${survey}/import/s3sign`;
    return callApi(dispatch, endpoint, token, 'post', { fileName, contentType }).then(
      ({ status, importId, signedUrl }) => {
        return { status, importId, signedUrl };
      },
    );
  };
}

export function startUpload(
  id,
  channel,
  token,
  file,
  peopleInput,
  getErrMessage,
  onProgress,
  navigate,
) {
  return dispatch => {
    dispatch(uploadStart(id));
    const body = { channel };
    if (peopleInput && peopleInput.length > 0) {
      body.peopleInput = peopleInput;
      callApi(dispatch, `surveys/${id}/people-upload`, token, 'post', body)
        .then(res => {
          dispatch(uploadDone(id, res.status, res.importId, res.totalPeople));
          if (res.status === 'ok') {
            navigate(`/surveys/${id}/import/${channel}/${res.importId}`);
          }
        })
        .catch(err => {
          const msg = getErrMessage(err);
          dispatch(setError(msg));
          dispatch(setSurveySubmitEnabled(true));
        });
    } else if (file) {
      dispatch(signS3Import(id, file.name, file.type, token)).then(({ signedUrl, importId }) => {
        return uploadSignedS3(signedUrl, file, onProgress).then(() => {
          callApi(dispatch, `surveys/${id}/import/${importId}/s3start`, token, 'put', {
            channel,
          }).then(res => {
            dispatch(uploadDone(id, res.status, res.importId, res.totalPeople));
            if (res.status === 'ok') {
              navigate(`/surveys/${id}/import/${channel}/${res.importId}`);
            }
          });
        });
      });
    }
  };
}

export function fetchSurveys(token) {
  return dispatch => {
    return callApi(dispatch, 'surveys', token).then(res => {
      dispatch(addSurveys(res));
      return res;
    });
  };
}

export function addPeople(people, surveyId) {
  return {
    type: ADD_PEOPLE,
    people,
    surveyId,
  };
}

export function fetchPeople(id, token) {
  return dispatch => {
    return callApi(dispatch, `surveys/${id}/people`, token).then(res => {
      dispatch(addPeople(res, id));
    });
  };
}

export function resetPeople() {
  return {
    type: RESET_PEOPLE,
  };
}

export function fetchImport(surveyId, importId, token) {
  return dispatch => {
    return promiseRetry(
      retry =>
        callApi(dispatch, `surveys/${surveyId}/import/${importId}`, token)
          .then(res => {
            dispatch(addPeople(res, surveyId));
            dispatch(uploadDone(surveyId, res.status, importId, res.totalPeople));
          })
          .catch(retry),
      { retries: 5 },
    );
  };
}

export function scheduleSendingDone(res) {
  return {
    type: SCHEDULE_SENDING_DONE,
    ...res,
  };
}

export function scheduleSending(surveyId, importId, date, token, navigate) {
  return dispatch => {
    return callApi(dispatch, `surveys/${surveyId}/import/${importId}/send`, token, 'put', {
      date,
    }).then(res => {
      if (res.status === 'ok') {
        dispatch(scheduleSendingDone(res));
        navigate(`/surveys/${surveyId}`);
      }
    });
  };
}

export function startTyping(response) {
  return {
    type: SOCKET_TYPING,
    response,
  };
}

export function stopTyping(response) {
  return {
    type: SOCKET_STOP_TYPING,
    response,
  };
}

export function listen(room) {
  return {
    type: SOCKET_LISTEN,
    surveyId: room,
  };
}

export function stopListening(surveyId) {
  return {
    type: SOCKET_STOP_LISTEN,
    surveyId,
  };
}

export function newResponseSocket(response, score) {
  return {
    type: SOCKET_NEW_RESPONSE,
    response,
    score,
  };
}

export function searchResults(hits, total) {
  return {
    type: SEARCH_RESULTS,
    hits,
    total,
  };
}

export function loadInputValues(surveyId, survey) {
  return {
    type: LOAD_INPUT_VALUES,
    surveyId,
    survey,
  };
}

export function newSurvey() {
  return {
    type: NEW_SURVEY,
  };
}

export function deleteSurveyDone(surveyId) {
  return {
    type: DELETE_SURVEY_DONE,
    surveyId,
  };
}

export function deleteSurvey(surveyId, token) {
  return dispatch => {
    return callApi(dispatch, `surveys/${surveyId}`, token, 'delete').then(res => {
      if (res.status === 'ok') {
        dispatch(deleteSurveyDone(surveyId));
      }
    });
  };
}

export function toggleChart() {
  return {
    type: TOGGLE_CHART,
  };
}

export function setChartPeriod(period) {
  return {
    type: SET_CHART_PERIOD,
    period,
  };
}

export function switchSurvey(id) {
  return {
    type: SWITCH_SURVEY,
    id,
  };
}

export function timeSeriesDone(res) {
  return {
    type: TIME_SERIES_DONE,
    res,
  };
}

export function customizeSurveyChangeTab(tab) {
  return {
    type: CUSTOMIZE_SURVEY_CHANGE_TAB,
    tab,
  };
}

export function fetchTimeSeries(surveyId, lastResponseAt, filter, token) {
  return dispatch => {
    const timeZone = getTimeZoneOffset();
    return callApi(dispatch, `surveys/${surveyId}/time-series`, token, 'post', {
      lastResponseAt,
      filter,
      timeZone,
    }).then(res => {
      dispatch(timeSeriesDone(res));
    });
  };
}

export function setTransliterate(transliterate) {
  return {
    type: SET_TRANSLITERATE,
    transliterate,
  };
}

export function clearErrorMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
  };
}

export function clearPeople() {
  return {
    type: CLEAR_PEOPLE,
  };
}

export function setWebSnippetStatusCb(status) {
  return {
    type: WEB_SNIPPET_STATUS_CB,
    status,
  };
}

export function setWebSnippetStatus(token, id, status) {
  return dispatch => {
    return callApi(dispatch, `surveys/${id}/web-snippet`, token, 'post', { status }).then(res => {
      if (res.ok) {
        dispatch(setWebSnippetStatusCb(status));
      }
    });
  };
}

export function updateSurveyCb(survey) {
  return {
    type: UPDATE_SURVEY_CB,
    survey,
  };
}

export function editSurvey(surveyId, props, tokenIn) {
  return (dispatch, getState) => {
    const token = tokenIn || getToken(getState());
    return callApi(dispatch, 'surveys', token, 'post', { id: surveyId, ...props }).then(survey => {
      dispatch(updateSurveyCb(survey));
    });
  };
}

export function setSurveyFields(token, id, fields) {
  return dispatch => {
    return callApi(dispatch, `surveys/${id}/fields`, token, 'put', { fields }).then(
      ({ ok, survey }) => {
        if (ok) {
          dispatch(updateSurveyCb(survey));
        }
      },
    );
  };
}

export function exportResultsCb(exportRequest) {
  return {
    type: EXPORT_RESULTS_CB,
    exportRequest,
  };
}

export const exportResults = (id, condition) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${id}/export`, token, 'post', { condition }).then(
    ({ ok, exportRequest }) => {
      if (ok) {
        dispatch(exportResultsCb(exportRequest));
      }
    },
  );
};

export const exportResultsListPending = {
  type: EXPORT_RESULTS_LIST_PENDING,
};

export const exportResultsListError = (message = '') => ({
  type: EXPORT_RESULTS_LIST_ERROR,
  message,
});

export function exportResultsListCb({ exportRequests }) {
  return {
    type: EXPORT_RESULTS_LIST_CB,
    exportRequests,
  };
}

export const exportResultsList = id => (dispatch, getState) => {
  dispatch(exportResultsListPending);
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${id}/export`, token, 'get')
    .then(({ ok, exportRequests }) => {
      if (ok) {
        dispatch(exportResultsListCb({ exportRequests }));
      } else {
        dispatch(exportResultsListError());
      }
    })
    .catch(err => {
      dispatch(exportResultsListError(err.message));
    });
};

export function clearExport() {
  return {
    type: CLEAR_EXPORT,
  };
}

export const addCustomization = (surveyId, customization) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/customization`, token, 'post', customization).then(
    survey => {
      dispatch(updateSurveyCb(survey));
      dispatch(loadInputValues(surveyId));
    },
  );
};

export const editCustomization =
  (surveyId, customizationId, customization) => (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(
      dispatch,
      `surveys/${surveyId}/customization/${customizationId}`,
      token,
      'put',
      customization,
    ).then(survey => {
      dispatch(updateSurveyCb(survey));
      setTimeout(() => dispatch(loadInputValues(surveyId)), 100);
    });
  };

export const deleteCustomization = (surveyId, customizationId) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(
    dispatch,
    `surveys/${surveyId}/customization/${customizationId}`,
    token,
    'delete',
  ).then(survey => {
    dispatch(updateSurveyCb(survey));
    dispatch(loadInputValues(surveyId));
  });
};

export const addQuestion = (surveyId, question) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/question`, token, 'post', question).then(survey => {
    dispatch(updateSurveyCb(survey));
    dispatch(loadInputValues(surveyId));
  });
};

export const editQuestion = (surveyId, questionId, question) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(
    dispatch,
    `surveys/${surveyId}/question/${questionId}`,
    token,
    'put',
    question,
  ).then(survey => {
    dispatch(updateSurveyCb(survey));
    dispatch(loadInputValues(surveyId));
  });
};

export const deleteQuestion = (surveyId, questionId) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/question/${questionId}`, token, 'delete').then(
    survey => {
      dispatch(updateSurveyCb(survey));
      dispatch(loadInputValues(surveyId));
    },
  );
};

export const moveQuestion = (surveyId, from, to) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/question/move`, token, 'put', { from, to }).then(
    survey => {
      dispatch(updateSurveyCb(survey));
      dispatch(loadInputValues(surveyId));
    },
  );
};

export const publishAdditionalQuestionsPreview = surveyId => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/additional-questions-preview`, token, 'post').then(
    survey => {
      dispatch(updateSurveyCb(survey));
      dispatch(loadInputValues(surveyId));
    },
  );
};

export const discardAdditionalQuestionsPreview = surveyId => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(
    dispatch,
    `surveys/${surveyId}/additional-questions-preview`,
    token,
    'delete',
  ).then(survey => {
    dispatch(updateSurveyCb(survey));
    dispatch(loadInputValues(surveyId));
  });
};

export const getNextQuestion =
  (surveyId, score, personProperties, language, questionId, value) => (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, `surveys/${surveyId}/next-question`, token, 'post', {
      value,
      personProperties,
      lastAnsweredQuestion: questionId,
      score,
      language,
    });
  };

export const editTranslationsAction = (surveyId, data) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/translations`, token, 'put', data).then(survey => {
    dispatch(updateSurveyCb(survey));
    dispatch(loadInputValues(surveyId, survey));
  });
};

export const removeLanguageAction = (surveyId, language) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/translations/${language}`, token, 'delete').then(
    survey => {
      dispatch(updateSurveyCb(survey));
      dispatch(loadInputValues(surveyId, survey));
    },
  );
};

export const autotagAction = (surveyId, responseId) => (dispatch, getState) => {
  const token = getToken(getState());
  return callApi(dispatch, `surveys/${surveyId}/responses/${responseId}/autotag`, token, 'post');
};
