/* eslint-disable react/prop-types */
import OptionButtonGroupContext from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButtonGroupContext';
import * as React from 'preact';
import { useRef, useMemo, useState, useEffect } from 'preact/hooks';

import styles from './OptionButton.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const OptionButtonGroup = props => {
  const { children, name, onChange, value: valueProp, multiple, ...other } = props;
  const prevMultiple = usePrevious(multiple);
  const prevName = usePrevious(name);
  const [value, setValue] = useState(valueProp || []);
  useEffect(() => {
    setValue(valueProp || value);
  }, [valueProp, multiple]);
  useEffect(() => {
    if (prevName === name && prevMultiple !== multiple) {
      setValue([]);
    }
  }, [multiple]);
  const contextValue = useMemo(
    () => ({
      name,
      onChange(event) {
        setValue(curr => {
          let found = false;
          const ret = curr.map(v => {
            found = found || v.id === event.target.value;
            return {
              ...v,
              checked:
                v.id === event.target.value
                  ? event.target.checked
                  : (v.checked && multiple) || false,
            };
          });
          if (!found) {
            ret.push({
              id: event.target.value,
              checked: event.target.checked,
              comment: '',
            });
          }
          if (typeof onChange === 'function' && ret !== valueProp) {
            setTimeout(() => onChange(ret), 1);
          }
          return ret;
        });
      },
      onCommentChange(event, id) {
        setValue(curr => {
          const ret = curr.map(v =>
            v.id === id
              ? {
                  ...v,
                  comment: event.target.value,
                }
              : v,
          );
          if (typeof onChange === 'function' && ret !== valueProp) {
            setTimeout(() => onChange(ret), 1);
          }
          return ret;
        });
      },
      value,
      multiple,
    }),
    [name, onChange, setValue, value],
  );

  return (
    <OptionButtonGroupContext.Provider value={contextValue}>
      <div className={styles.group} {...other}>
        {children}
      </div>
    </OptionButtonGroupContext.Provider>
  );
};

export default OptionButtonGroup;
