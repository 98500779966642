/* eslint-disable global-require,import/no-extraneous-dependencies */
import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';
import { helpSlice } from 'client/modules/Help/helpSlice';
import { peopleApiSlice } from 'client/modules/People/peopleApiSlice';
import { apiKeysSlice } from 'client/modules/Surveys/apiKeysSlice';
import { folderApiSlice } from 'client/modules/Surveys/folderApiSlice';
import { linkApiSlice } from 'client/modules/Surveys/linkApiSlice';
import { savedSearchApiSlice } from 'client/modules/Surveys/savedSearchApiSlice';
import { surveyAnalyzeApiSlice } from 'client/modules/Surveys/surveyAnalyzeApiSlice';
import { surveyDriverApiSlice } from 'client/modules/Surveys/surveyDriverApiSlice';
import * as jwt from 'jsonwebtoken';
import throttle from 'lodash/throttle';
/**
 * Main store function
 */
import createSocketIoMiddleware from 'redux-socket.io';
import io from 'socket.io-client';
import Cookies from 'universal-cookie';
import { enabledLanguages } from '../Intl/setup';
import { createI18nData, getI18n } from './AppReducer';
import { getNextPath, getProfile, getToken } from './modules/Auth/AuthReducer';
import { loadState, saveState } from './persistedState';

import appReducer from './reducers';
import mergeDeep from './util/mergeDeep';

const configureStore = (initialState = {}, req) => {
  let combinedInitialState = initialState;
  const generatedState = { app: { i18n: {} }, auth: {} };
  const enhancers = [
  ];
  if (process.env.CLIENT) {
    // Let's not send data to Google or anywhere outside EU. :)
    // const analyticsMiddleware = createMiddleware(eventsMap, GoogleTagManager());

    const socket = io();
    const socketIoMiddleware = createSocketIoMiddleware(socket, 'SOCKET_');

    // Middleware and store enhancers
    // enhancers.push(applyMiddleware(analyticsMiddleware)); // create and push ReduxGTM
    enhancers.push(socketIoMiddleware);

    if (process.env.NODE_ENV === 'development') {
      const Debug = require('redux-debug');
      const debug = require('debug')('redux');
      enhancers.push(Debug(debug)); // change debug by console.log()?
    }

    const persistedState = loadState() || {};
    combinedInitialState = mergeDeep(persistedState, initialState);
    if (combinedInitialState.app && combinedInitialState.app.i18n) {
      combinedInitialState.app.i18n = createI18nData(combinedInitialState, combinedInitialState.app.i18n.locale);
    }
    generatedState.app.userAgent = navigator.userAgent;

    // Should never be used as it should come in initialState from the preloaded state generated at the server
    let locale = navigator.language || navigator.userLanguage;
    if (typeof locale === 'string' && locale.indexOf('-') > -1) {
      [locale] = locale.split('-');
    }
    if (enabledLanguages.indexOf(locale) < 0) {
      [locale] = enabledLanguages;
    }
    generatedState.app.i18n.locale = locale;
  } else {
    generatedState.app.userAgent = req && req.headers ? req.headers['user-agent'] : 'all';
    const cookies = new Cookies(req.headers?.cookie);
    let locale = cookies.get('locale');
    if (enabledLanguages.indexOf(locale) < 0) {
      ({ locale } = req); // Created from accept-language negotiation at server.js
    }
    if (enabledLanguages.indexOf(locale) < 0) {
      [locale] = enabledLanguages; // Should never happen
    }
    generatedState.app.i18n = createI18nData(generatedState, locale);
    if (cookies && cookies.get('token')) {
      const token = cookies.get('token');
      const DEFAULT_SESSION_LENGTH = '15d';
      const options = {
        algorithm: 'HS512',
        expiresIn: DEFAULT_SESSION_LENGTH,
        audience: process.env.AUTH0_CLIENT_ID,
        issuer: process.env.AUTH0_CLIENT_ISSUER,
        subject: cookies.get('user_id'),
      };
      generatedState.auth.profile = jwt.decode(token, options);
      if (generatedState.auth.profile) {
        generatedState.auth.profile.token = token;
      }
    }
  }

  combinedInitialState = mergeDeep(generatedState, combinedInitialState);

  const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === 'RECEIVE_LOGOUT') {
      newState = initialState;
      newState.app = state.app;
    }
    return appReducer(newState, action);
  };
  const store = configureStoreRTK({
    reducer: rootReducer,
    preloadedState: combinedInitialState,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [
          'app', 'app2', 'auth', 'confirm', 'content', 'emailPreferences', 'integrations', 'pairwise',
          'people', 'pricing', 'responses', 'routing', 'settings', 'subscriptions', 'surveys',
        ],
      },
      serializableCheck: {
        ignoredActions: ['confirm/open'],
        ignoredPaths: [
          'app', 'app2', 'auth', 'confirm', 'content', 'emailPreferences', 'integrations', 'pairwise',
          'people', 'pricing', 'responses', 'routing', 'settings', 'subscriptions', 'surveys',
        ],
      },
    }).concat([
      apiKeysSlice.middleware,
      folderApiSlice.middleware,
      helpSlice.middleware,
      linkApiSlice.middleware,
      peopleApiSlice.middleware,
      savedSearchApiSlice.middleware,
      surveyAnalyzeApiSlice.middleware,
      surveyDriverApiSlice.middleware,
      ...enhancers,
    ]),
  });
  let cookies;
  if (process.env.CLIENT) {
    cookies = new Cookies();
  } else {
    cookies = req.universalCookies;
  }
  store.subscribe(throttle(() => {
    const state = store.getState();
    const i18n = getI18n(state);
    const profile = getProfile(state);
    const token = getToken(state);
    const nextPath = getNextPath(state);
    const stateToSave = {
      auth: { profile, nextPath },
      app: {},
    };
    if (i18n) {
      stateToSave.app.i18n = { locale: i18n.locale };
    }
    saveState(stateToSave);
    const ONE_YEAR = 31556926;
    const secure = process.env.NODE_ENV === 'production';
    cookies.set('token', token, {
      maxAge: ONE_YEAR, secure, httpOnly: false, path: '/',
    });
    if (profile) {
      cookies.set('user_id', profile.user_id, {
        maxAge: ONE_YEAR, secure, httpOnly: false, path: '/',
      });
    } else {
      cookies.remove('user_id');
    }
    cookies.set('locale', i18n.locale, {
      maxAge: ONE_YEAR, secure, httpOnly: false, path: '/',
    });
  }, 1000));
  // For hot reloading reducers
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore;
