import { getToken } from 'client/modules/Auth/AuthReducer';
import { createAction } from 'redux-actions';
import callApi from '../../util/apiCaller';
import { reloadSearch } from '../Surveys/SurveyActions';

export const addResponseCb = createAction('ADD_RESPONSE_CB');
export const alreadyRespondedCb = createAction('ALREADY_RESPONDED_CB');
export const surveyNotFoundCb = createAction('NOT_FOUND_CB');
export const surveyDisabledCb = createAction('DISABLED_CB');
export const deleteResponseCb = createAction('DELETE_RESPONSE_CB');
export const textareaChange = createAction('TEXTAREA_CHANGE');
export const fetchResponseCb = createAction('FETCH_RESPONSE_CB');
export const setTagsCb = createAction('SET_TAGS_CB');
export const receivedResponse = createAction('RECEIVED_RESPONSE');
export const fetchResponseErrorCb = createAction('FETCH_RESPONSE_ERROR_CB');
export const fetchTagsCb = createAction('FETCH_TAGS_CB');
export const fetchTagsErrorCb = createAction('FETCH_TAGS_ERROR_CB');

export function addResponse(responseId, response, navigate, cb) {
  return dispatch => {
    return callApi(dispatch, `response/${responseId}`, null, 'PUT', response)
      .then(res => {
        if (res) {
          dispatch(addResponseCb(res));
          if (cb) {
            cb(res);
          }
        }
        if (typeof navigate === 'function') {
          if (typeof res.nextQuestion === 'number' && res.nextQuestion >= 0) {
            navigate(`/response/${responseId}/q/${res.nextQuestion}`);
          } else {
            navigate(`/response/${responseId}/thanks`);
          }
        }
        return res;
      })
      .catch(err => {
        if (err.status === 409) {
          dispatch(alreadyRespondedCb());
          return;
        }
        if (err.status !== 404) {
          throw err;
        }
      });
  };
}

export function fetchPublicResponse(responseId, score) {
  return dispatch => {
    let endpoint = `response/${responseId}`;
    if (typeof score !== 'undefined') {
      endpoint += `?score=${score}`;
    }
    return callApi(dispatch, endpoint, null, 'GET')
      .then(res => {
        dispatch(addResponseCb(res));
        if (res.followupStrategy === 'none') {
          // Redirect to /thenks page

        }
        return res;
      })
      .catch(err => {
        if (err.status === 409) {
          dispatch(alreadyRespondedCb(err.bodyJson));
          return null;
        }
        if (err.status === 404) {
          dispatch(surveyNotFoundCb(err.bodyJson));
          return null;
        }
        if (err.status === 406) {
          dispatch(surveyDisabledCb(err.bodyJson));
          return null;
        }
        dispatch(fetchResponseErrorCb(err));
        throw err;
      });
  };
}

export function fetchResponse(token, surveyId, responseId) {
  return dispatch => {
    return callApi(dispatch, `surveys/${surveyId}/responses/${responseId}`, token)
      .then(res => {
        dispatch(fetchResponseCb(res));
      })
      .catch(err => {
        dispatch(fetchResponseErrorCb(err));
        throw err;
      });
  };
}

export function deleteResponse(token, surveyId, responseId, cb) {
  return dispatch => {
    return callApi(dispatch, `/surveys/${surveyId}/responses/${responseId}`, token, 'DELETE').then(
      () => {
        dispatch(deleteResponseCb({ id: responseId }));
        setTimeout(() => dispatch(reloadSearch()), 100);
        if (cb) {
          cb({ ok: true, surveyId, responseId });
        }
      },
    );
  };
}

export function setTags(token, surveyId, responseId, tags) {
  return dispatch => {
    return callApi(dispatch, `/surveys/${surveyId}/responses/${responseId}`, token, 'PUT', {
      tags,
    }).then(res => {
      dispatch(setTagsCb({ tags: res.body.tags, id: responseId }));
    });
  };
}

export function fetchTags(token, surveyId) {
  return dispatch => {
    return callApi(dispatch, `surveys/${surveyId}/tags`, token, 'POST')
      .then(res => {
        dispatch(fetchTagsCb(res));
        return res;
      })
      .catch(err => {
        dispatch(fetchTagsErrorCb(err));
        throw err;
      });
  };
}

export function deleteTagAction(surveyId, tag) {
  return async (dispatch, getState) => {
    const token = getToken(getState());
    try {
      const res = await callApi(dispatch, `surveys/${surveyId}/tags/${tag}`, token, 'DELETE');
      setTimeout(() => dispatch(fetchTags(token, surveyId)), 1000);
      return res;
    } catch (err) {
      dispatch(fetchTagsErrorCb(err));
      throw err;
    }
  };
}

export function addTagsAction(surveyId, tags) {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, `surveys/${surveyId}/tags/new`, token, 'POST', { tags })
      .then(res => {
        setTimeout(() => dispatch(fetchTags(token, surveyId)), 1000);
        return res;
      })
      .catch(err => {
        dispatch(fetchTagsErrorCb(err));
        throw err;
      });
  };
}
export function replaceTagAction(surveyId, tag, newTag) {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, `surveys/${surveyId}/tags/${tag}/replace/${newTag}`, token, 'PUT')
      .then(res => {
        setTimeout(() => dispatch(fetchTags(token, surveyId)), 1000);
        return res;
      })
      .catch(err => {
        dispatch(fetchTagsErrorCb(err));
        throw err;
      });
  };
}

export function addAdditionalQuestionResponse(responseId, questionId, value, previewSurvey) {
  return dispatch => {
    return callApi(
      dispatch,
      `response/${previewSurvey ? 'preview' : responseId}/question/${questionId}`,
      null,
      'PUT',
      { value, previewSurvey },
    )
      .then(res => {
        dispatch(fetchResponseCb(res));
        return res;
      })
      .catch(err => {
        dispatch(fetchResponseErrorCb(err));
        throw err;
      });
  };
}

export function addFromLink(link, params, properties) {
  return dispatch =>
    callApi(dispatch, `link/${link}/responses`, null, 'POST', { ...params, properties });
}
