import { Add, Delete } from '@mui/icons-material';
import { Box, Button, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import translations from 'client/modules/Response/pages/AddResponsePages/translations.svg';
import AddLanguagesDialog from 'client/modules/Surveys/pages/CustomizeSurvey/AddLanguagesDialog';
import CustomizeTranslationsLanguage from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeTranslationsLanguage';
import { scale } from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import { editTranslationsAction, removeLanguageAction } from 'client/modules/Surveys/SurveyActions';
import {
  getAdditionalQuestions,
  getCustomizations,
  getId,
  getInputValue,
  getSurveyType,
} from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import { localizationData, surveyLanguages } from 'Intl/setup';
import upperFirst from 'lodash/upperFirst';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Sticky from 'react-stickynode';
import SVGInline from 'react-svg-inline';

const messages = defineMessages({
  additional: {
    id: 'CustomizeTranslations.Additional',
    defaultMessage: 'Additional {index}: {name}',
  },
  text: {
    id: 'CustomizeTranslations.keys.text',
    defaultMessage: 'text',
  },
  left: {
    id: 'CustomizeTranslations.keys.left',
    defaultMessage: 'left',
  },
  right: {
    id: 'CustomizeTranslations.keys.right',
    defaultMessage: 'right',
  },
});
const CustomizeTranslations = () => {
  const [saving, setSaving] = useState(false);
  const [isAddLanguagesOpen, openAddLanguages, closeAddLanguages] = useOpenClose();
  const [editTranslations, removeLanguage] = useActions([
    editTranslationsAction,
    removeLanguageAction,
  ]);
  const values = useSelector(state => state.surveys);
  const { formatMessage, locale } = useIntl();
  const baseLang = useSelector(
    state => getInputValue('surveyLanguage', state) || state.app.i18n.locale,
  );
  const customizations = useSelector(getCustomizations);
  const additionalQuestions = useSelector(getAdditionalQuestions);
  const languages = useMemo(() => {
    let ret = Object.keys(
      customizations.reduce((acc, curr) => ({ ...acc, [curr.language]: true }), {}),
    ).sort();
    if (!ret.includes(baseLang)) {
      ret = [...ret, baseLang].sort();
    }
    return ret;
  }, [customizations, baseLang]);
  const surveyType = useSelector(getSurveyType) || 'nps';
  const surveyId = useSelector(getId);
  const handleOpenConfirm = useActions(openConfirm);

  const customizationMap = useMemo(() => {
    const ret = languages.reduce((acc, curr) => ({ ...acc, [curr]: {} }), {});
    customizations.forEach(c => {
      if (ret[c.language]) {
        ret[c.language][c.name] = c;
      }
    });
    return ret;
  }, [languages, customizations]);

  const getDefault = (lang, key) => {
    let ret = localizationData[lang]?.messages[`${surveyType}.${key}`];
    if (key === 'subject' && !ret) {
      ret = getDefault(lang, 'question');
    }
    return ret;
  };

  const getInitialLanguageRows = useCallback(
    lang => {
      const initialRows = Object.entries({
        fromName: 'From',
        replyTo: 'Reply',
        question: '',
        subject: '',
        intro: '',
        footer: '',
        followUpQuestion: '',
      }).map(([key, msgKey]) => {
        return {
          id: key,
          label: formatMessage({ id: `Survey.${msgKey || upperFirst(key)}` }),
          baseDefault: getDefault(baseLang, key),
          baseLang: customizationMap[baseLang]?.[key]?.value || values[key],
          selectedDefault:
            getDefault(lang, key) ||
            (['fromName', 'replyTo', 'intro'].includes(key) ? values[key] : ''),
          selectedLang: customizationMap[lang]?.[key]?.value,
          customizationId: customizationMap[lang]?.[key]?._id,
          touched: false,
        };
      });
      const names = {
        text: formatMessage(messages.text),
        scaleMinLabel: formatMessage(messages.left),
        scaleMaxLabel: formatMessage(messages.right),
      };

      additionalQuestions.forEach((q, index) => {
        [
          'text',
          'intro',
          'scaleMinLabel',
          'scaleMaxLabel',
          'buttonLabel',
          ...(q.optionsAttributes || []),
        ].forEach(key => {
          const id = `aq-${q._id}-${typeof key === 'object' ? key._id : key}`;
          const base =
            customizationMap[baseLang][id]?.value ||
            q[key] ||
            (typeof key === 'object' && q.optionsAttributes.find(o => o._id === key._id)?.text) ||
            undefined;
          if (key === 'text' || typeof key === 'object' || (q.type === scale && q[key])) {
            initialRows.push({
              id,
              label: formatMessage(messages.additional, {
                index: index + 1,
                name: typeof key === 'object' ? key.text : names[key],
              }),
              baseLang: base,
              selectedDefault: lang === baseLang ? base : '',
              selectedLang: customizationMap[lang]?.[id]?.value,
              customizationId: customizationMap[lang]?.[id]?._id,
            });
          }
        });
      });
      return initialRows;
    },
    [baseLang, additionalQuestions, values, formatMessage, customizationMap],
  );

  const defaultValues = useMemo(
    () => ({
      languages: languages.map(lang => ({
        language: lang,
        translations: getInitialLanguageRows(lang),
      })),
      selectedLang: languages?.[0],
    }),
    [languages, getInitialLanguageRows],
  );

  const methods = useForm({ defaultValues });

  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'languages' });

  const selectedLang = methods.watch('selectedLang');

  const handleSaveClick = async form => {
    try {
      setSaving(true);
      const data = form.languages.map(row => ({
        language: row.language,
        translations: row.translations.map(t => ({
          value: t.selectedLang,
          customizationId: t.customizationId,
          name: t.id,
        })),
      }));
      await editTranslations(surveyId, data);
    } finally {
      setSaving(false);
    }
  };

  const deleteLanguage = (lang, idx) => () => {
    if (languages.includes(lang)) {
      const language = surveyLanguages[locale][lang];
      handleOpenConfirm({
        danger: true,
        title: (
          <FormattedMessage
            id="CustomizeTranslations.Are you sure"
            defaultMessage="Are you sure you want to delete {language}?"
            values={{ language }}
          />
        ),
        text: (
          <FormattedMessage
            id="CustomizeTranslations.This will remove"
            defaultMessage="This action will remove your current {language} customizations and you won't be able to recover them."
            values={{ language }}
          />
        ),
        onOk: async () => {
          remove(idx);
          await removeLanguage(surveyId, lang);
          methods.setValue('selectedLang', idx !== 0 ? languages?.[0] : languages?.[1]);
        },
      });
    }
  };

  const handleAddLanguages = newLanguages => {
    newLanguages.forEach(lang => {
      append({
        language: lang,
        translations: getInitialLanguageRows(lang),
      });
    });
    if (newLanguages.length > 0) {
      setTimeout(() => methods.setValue('selectedLang', newLanguages[0]), 1);
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 1280,
        alignSelf: 'center',
        my: 3,
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}
      id="translations-root"
    >
      {languages?.length > 0 ? (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSaveClick)}>
            <Sticky innerZ={100} enabled bottomBoundary="#translations-root">
              <Grid container sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}>
                <Grid xs={12} sm={6} sx={{ p: 2 }}>
                  <Typography>
                    <FormattedMessage
                      id="CustomizeTranslations.Default language"
                      defaultMessage="Default language ({language})"
                      values={{ language: surveyLanguages[locale][baseLang] }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="standard"
                        disableUnderline
                        sx={{ '& .MuiSelect-select': { p: 0 } }}
                        renderValue={value => (
                          <Typography>{value && surveyLanguages[locale][value]}</Typography>
                        )}
                      >
                        {fields.sort().map(({ language }, idx) => (
                          <MenuItem
                            key={language}
                            value={language}
                            sx={{ pr: 7 }}
                            divider={idx === fields.length - 1}
                          >
                            <ListItemText primary={surveyLanguages[locale][language]} />
                            {language !== baseLang && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={deleteLanguage(language, idx)}
                                >
                                  <Delete />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={false}
                          sx={{ color: 'primary.main' }}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            openAddLanguages();
                          }}
                        >
                          <FormattedMessage
                            id="CustomizeTranslations.Add language"
                            defaultMessage="Add language"
                          />
                        </MenuItem>
                      </Select>
                    )}
                    name="selectedLang"
                    control={methods.control}
                  />
                  <Button variant="contained" color="primary" type="submit" disabled={saving}>
                    {saving ? (
                      <FormattedMessage
                        id="CustomizeTranslations.Saving"
                        defaultMessage="Saving..."
                      />
                    ) : (
                      <FormattedMessage id="CustomizeTranslations.Save" defaultMessage="Save" />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Divider />
            </Sticky>
            <CustomizeTranslationsLanguage key={selectedLang} control={methods.control} />
          </form>
        </FormProvider>
      ) : (
        <Box
          sx={{
            maxWidth: 400,
            alignSelf: 'center',
            textAlign: 'center',
            flexGrow: 1,
            p: 2,
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ color: 'action.active', width: 100, height: 100 }}>
            <SVGInline svg={translations} />
          </Box>
          <Typography>
            <FormattedMessage
              id="CustomizeTranslations.YouDontHave"
              defaultMessage="You don't have any additional languages yet. Click the button below to add some."
            />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={openAddLanguages}
            sx={{ mt: 2 }}
            startIcon={<Add />}
          >
            <FormattedMessage
              id="CustomizeTranslations.Add languages"
              defaultMessage="Add languages"
            />
          </Button>
        </Box>
      )}
      <AddLanguagesDialog
        isOpen={isAddLanguagesOpen}
        close={closeAddLanguages}
        onAdd={handleAddLanguages}
        languages={languages}
      />
    </Paper>
  );
};

export default CustomizeTranslations;
