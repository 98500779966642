/* eslint-disable react/require-default-props */
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { setOk } from 'client/modules/App/AppActions';
import slugify from 'client/util/slugify';
import FormsyRadio from 'components/FormsyRadioGroup/FormsyRadio';
import FormsyRadioGroup from 'components/FormsyRadioGroup/FormsyRadioGroup';
import FormsyText from 'components/FormsyText/FormsyText';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import SelectField from 'components/SelectField/SelectField';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { surveyLanguages } from 'Intl/setup';
import withLocation from '../../../../util/withLocation';

import { getProfile, getToken } from '../../../Auth/AuthReducer';
import { ThankyouPageDisconnected } from '../../../Response/pages/AddResponsePages/ThankyouPage';

import {
  customizationInputChange,
  inputChange,
  postSurvey,
  setSurveySubmitEnabled,
} from '../../SurveyActions';

import {
  canSubmit as getCanSubmit,
  getCustomization,
  getCustomizations,
  getId,
  getInputValue,
  getLogoUrl,
  getRand,
  getSubmitButtonMessage,
  getSurveyType,
  surveyMessages,
} from '../../SurveyReducer';

import styles from './CustomizeEmail.css';

const messages = defineMessages({
  surveySaved: {
    id: 'Survey.Saved',
    defaultMessage: 'Survey saved successfully',
  },
});
const languageItems = locale =>
  Object.keys(surveyLanguages[locale]).map(lang => (
    <MenuItem key={lang} value={lang}>
      {surveyLanguages[locale][lang]}
    </MenuItem>
  ));

const CustomizeThankyou = props => {
  const {
    intl: { formatMessage, locale },
  } = props;

  const handleChange = event => {
    props.customizationInputChange(event.target.id, event.target.value, props.thankyouLanguage);
  };

  const handleInputChange = (name, event) => {
    const { value } = event.target;
    props.inputChange(name, value);
  };

  const enableButton = () => {
    props.setSurveySubmitEnabled(true);
  };

  const disableButton = () => {
    props.setSurveySubmitEnabled(false);
  };

  const handleSubmit = () => {
    props.setSurveySubmitEnabled(false, formatMessage(surveyMessages['Survey.Submitting']));
    const {
      _id: id,
      topColor,
      brand,
      name,
      fromName,
      fromEmail,
      replyTo,
      subject,
      intro,
      customizations,
      thankyouStrategy,
      logoUrl,
    } = props;
    const properties = {
      id,
      topColor,
      brand,
      name,
      fromName,
      fromEmail,
      replyTo,
      subject,
      intro,
      customizations,
      thankyouStrategy,
      language: props.surveyLanguage || props.intl.locale,
    };
    if (logoUrl) {
      properties.logoUrl = logoUrl;
    }
    props.postSurvey(properties, props.token);
    props.setOk(formatMessage(messages.surveySaved), 4000);
  };

  const {
    style,
    _id: id,
    topColor,
    brand,
    logoUrl,
    rand,
    canSubmit,
    submitButtonMessage,
    thankyouLinkURL,
    thankyouLinkText,
    thankyouMessage,
    thankyouTitle,
    thankyouStrategy,
    thankyouLanguage,
    customizationGroup,
    surveyType,
  } = props;
  const customizationGroups =
    surveyType === 'nps' ? ['0-3', '4-6', '7-8', '9-10'] : ['1-2', '3', '4-5'];
  return (
    <Formsy.Form onValid={enableButton} onInvalid={disableButton} onValidSubmit={handleSubmit}>
      <Grid className={styles['main-grid']} style={style} fluid>
        <Box component={Row} className={styles['main-content']}>
          <Col className={styles.controls} xs={12} sm={5} md={4} lg={4}>
            <Helmet title={formatMessage(surveyMessages['Survey.Personalize.Title'])} />
            <Paper className={styles.form}>
              <div className={styles.formInner}>
                <FormsyRadioGroup
                  fullWidth
                  id="thankyouStrategy"
                  name="thankyouStrategy"
                  value={thankyouStrategy}
                  defaultSelected={thankyouStrategy}
                  onChange={handleInputChange.bind(null, 'thankyouStrategy')}
                  className={styles.strategy}
                >
                  <FormsyRadio
                    label={formatMessage(surveyMessages['Survey.ThankyouPageStatic'])}
                    value="static"
                  />
                  <FormsyRadio
                    label={formatMessage(surveyMessages['Survey.ThankyouPageDynamic'])}
                    value="dynamic"
                  />
                </FormsyRadioGroup>
                <Box sx={{ width: '100%' }}>
                  <SelectField
                    label={formatMessage(surveyMessages['Survey.Language'])}
                    id="thankyouLanguage"
                    sx={{ color: 'action.active' }}
                    disableUnderline
                    value={thankyouLanguage || locale}
                    onChange={handleInputChange.bind(null, 'thankyouLanguage')}
                  >
                    {languageItems(locale)}
                  </SelectField>
                </Box>
                {thankyouStrategy === 'dynamic' ? (
                  <ToggleButtonGroup
                    sx={{ mt: 2 }}
                    fullWidth
                    id="customizationGroup"
                    label={formatMessage(surveyMessages['Survey.ThankyouPageCustomizationGroup'])}
                    value={customizationGroup || locale}
                    exclusive
                    onChange={handleInputChange.bind(null, 'customizationGroup')}
                  >
                    {customizationGroups.map(score => {
                      const value = `group${score.replace('-', '')}`;
                      return (
                        <ToggleButton key={value} value={value}>
                          {score}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                ) : null}

                <FormsyText
                  fullWidth
                  id="thankyouTitle"
                  name="thankyouTitle"
                  value={thankyouTitle}
                  multiLine
                  rows={3}
                  label={formatMessage(surveyMessages['Survey.ThankyouTitle'])}
                  onChange={handleChange}
                />

                <FormsyText
                  fullWidth
                  id="thankyouMessage"
                  name="thankyouMessage"
                  value={thankyouMessage}
                  multiLine
                  rows={3}
                  label={formatMessage(surveyMessages['Survey.ThankyouMessage'])}
                  onChange={handleChange}
                />

                <FormsyText
                  fullWidth
                  id="thankyouLinkText"
                  name="thankyouLinkText"
                  value={thankyouLinkText}
                  label={formatMessage(surveyMessages['Survey.ThankyouLinkText'])}
                  onChange={handleChange}
                />

                <FormsyText
                  fullWidth
                  id="thankyouLinkURL"
                  name="thankyouLinkURL"
                  value={thankyouLinkURL}
                  label={formatMessage(surveyMessages['Survey.ThankyouLinkURL'])}
                  onChange={handleChange}
                />

                <RaisedButton
                  type="submit"
                  className={styles.submit}
                  label={
                    submitButtonMessage || id
                      ? formatMessage(surveyMessages['Survey.Save'])
                      : formatMessage(surveyMessages['Survey.Next'])
                  }
                  disabled={!canSubmit}
                  primary
                />
              </div>
            </Paper>
          </Col>
          <Col xs={12} sm={7} md={8} lg={8}>
            <Paper className={styles.preview}>
              <ThankyouPageDisconnected
                brand={brand}
                topColor={topColor}
                language={thankyouLanguage}
                logoUrl={logoUrl}
                rand={rand}
                message={thankyouMessage}
                title={thankyouTitle}
                linkText={thankyouLinkText}
                linkURL={thankyouLinkURL}
                preview
              />
            </Paper>
          </Col>
        </Box>
      </Grid>
    </Formsy.Form>
  );
};

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
  let fromEmail = slugify(getInputValue('brand', state));
  if (fromEmail === null || fromEmail.length < 1) {
    fromEmail = 'surveys';
  }
  return {
    canSubmit: getCanSubmit(state),
    _id: getId(state),
    topColor: getInputValue('topColor', state),
    brand: getInputValue('brand', state),
    name: getInputValue('name', state),
    thankyouLanguage:
      getInputValue('thankyouLanguage', state) || getInputValue('surveyLanguage', state),
    surveyLanguage: getInputValue('surveyLanguage', state),
    token: getToken(state),
    submitButtonMessage: getSubmitButtonMessage(state),
    logoUrl: getLogoUrl(state),
    thankyouMessage: getCustomization('thankyouMessage', state, state.surveys.thankyouStrategy),
    thankyouTitle: getCustomization('thankyouTitle', state, state.surveys.thankyouStrategy),
    thankyouLinkText: getCustomization('thankyouLinkText', state, state.surveys.thankyouStrategy),
    thankyouLinkURL: getCustomization('thankyouLinkURL', state, state.surveys.thankyouStrategy),
    fromName: getInputValue('fromName', state),
    fromEmail: `${fromEmail}@promoterninja.com`,
    replyTo: getInputValue('replyTo', state) || getProfile(state).email,
    subject: getInputValue('subject', state),
    intro: getInputValue('intro', state),
    customizations: getCustomizations(state),
    thankyouStrategy: getInputValue('thankyouStrategy', state),
    customizationGroup: getInputValue('customizationGroup', state),
    rand: `${getRand(state)}`,
    surveyType: getSurveyType(state) || ownProps.location.query.type || 'nps',
  };
}

CustomizeThankyou.propTypes = {
  intl: PropTypes.object,
  _id: PropTypes.string,
  topColor: PropTypes.string,
  surveyLanguage: PropTypes.string,
  thankyouLanguage: PropTypes.string,
  brand: PropTypes.string,
  name: PropTypes.string,
  fromName: PropTypes.string,
  fromEmail: PropTypes.string,
  replyTo: PropTypes.string,
  subject: PropTypes.string,
  intro: PropTypes.string,
  logoUrl: PropTypes.string,
  canSubmit: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  submitButtonMessage: PropTypes.string,
  postSurvey: PropTypes.func.isRequired,
  setSurveySubmitEnabled: PropTypes.func.isRequired,
  thankyouMessage: PropTypes.string,
  thankyouTitle: PropTypes.string,
  thankyouLinkText: PropTypes.string,
  thankyouLinkURL: PropTypes.string,
  inputChange: PropTypes.func.isRequired,
  customizationInputChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  customizations: PropTypes.array,
  thankyouStrategy: PropTypes.string,
  customizationGroup: PropTypes.string,
  rand: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  setOk: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setOk(msg, timeout) {
    dispatch(setOk(msg, timeout));
  },
  setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage) {
    dispatch(setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage));
  },
  postSurvey(model, token) {
    dispatch(postSurvey(model, token));
  },
  customizationInputChange(name, value, language) {
    dispatch(customizationInputChange(name, value, language, true));
  },
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
});

export default withLocation(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomizeThankyou)),
);
